import { useLocalStorage } from 'react-use';
import add from 'date-fns/add';
import { Duration, isBefore } from 'date-fns';
import { useEffect } from 'react';

export interface StorageValue {
  id: string;
  expires: string;
}

// TODO: search-condはidを保存するものではないので、検索条件が出揃ったら後日別途対応する
interface Props {
  key: 'favorite' | 'history' | 'favorite-agency' | 'agency-history' | 'search-cond';
}

export const useSaveItemsToLocalStorage = ({ key }: Props) => {
  const isProperty = key === 'favorite' || key === 'history';
  const expireDuration: Duration = isProperty ? { days: 30 } : { days: 90 };
  const limitNum = isProperty ? 50 : 20;
  const initialValue: StorageValue[] = [];
  const [value, setValue, remove] = useLocalStorage(key, initialValue);

  useEffect(() => {
    if (value?.length) {
      const filterValue = value.filter((item) => isBefore(new Date(), new Date(item.expires)));
      if (!filterValue.length) {
        remove();
      }
      setValue(filterValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addItems = (ids: string[]) => {
    const expires = add(new Date(), expireDuration).toString();
    const newItems = ids.map((id) => ({ id, expires }));
    // すでに保存されているIDはあらたな有効期限を付与したものと置き換えて保存する
    const saveItems = value ? value.filter((v) => !ids.includes(v.id)).concat(newItems) : newItems;
    setValue(saveItems.slice(-limitNum));
  };

  const removeItems = (ids: string[]) => {
    if (!value) {
      return;
    }
    const filterValue = value.filter((item) => !ids.includes(item.id));
    if (!filterValue.length) {
      remove();
      return;
    }
    setValue(filterValue);
  };

  const savedItem = (id: string) => {
    if (!value) {
      return false;
    }
    return Boolean(value.filter((item) => item.id === id).length);
  };

  const removeAll = () => remove();

  return {
    value,
    count: value?.length || 0,
    addItems,
    removeItems,
    savedItem,
    removeAll,
  };
};
