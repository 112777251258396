import { createTheme, experimental_sx as sx } from '@mui/material/styles';
import { red } from '@mui/material/colors';

declare module '@mui/material/styles' {
  interface Palette {
    accent: Palette['primary'];
  }
  interface PaletteOptions {
    accent: PaletteOptions['primary'];
  }
}
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    accent: true;
  }
}

declare module '@mui/material/Badge' {
  interface BadgePropsColorOverrides {
    accent: true;
  }
}

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    headerBP: true; // adds a custom breakpoint
  }
}

const defaultTheme = createTheme();

// Create a theme instance.
const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 640,
      headerBP: 830,
      md: 1000,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
    primary: {
      main: '#008900',
      light: '#0aa736',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#ffa3ce',
    },
    accent: {
      main: '#fc5B31',
      light: '#ff8024',
      contrastText: '#ffffff',
    },
    error: {
      main: red.A400,
    },
    text: {
      primary: '#020202',
    },
  },
  typography: {
    fontFamily:
      "'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', 'メイリオ', 'meiryo', 'ＭＳ Ｐゴシック', 'MS P Gothic', 'ヒラギノ丸ゴシック Pro W3', 'Hiragino maru Gothic Pro', 'Helvetica', 'sans-serif'",
    h1: {
      fontSize: '3em',
      fontWeight: 700,
    },
    h2: {
      fontSize: '2em',
      fontWeight: 500,
    },
    h3: {
      fontSize: '1.5em',
      fontWeight: 700,
    },
    body1: {
      fontSize: '1em',
      fontWeight: 400,
      lineHeight: 1.5,
    },
    body2: {
      fontSize: '0.875em',
      fontWeight: 400,
      lineHeight: 1.43,
      letterSpacing: '0.01071em',
    },
    caption: {
      fontSize: '0.75em',
      fontWeight: 400,
      lineHeight: 1.66,
      [defaultTheme.breakpoints.down('sm')]: {
        fontSize: '0.625em',
      },
    },
    button: {
      fontSize: 14,
      textTransform: 'none',
    },
  },
  components: {
    MuiChip: {
      styleOverrides: {
        root: {
          fontSize: '10px',
          fontWeight: 500,
          borderRadius: '11px',
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          fontSize: '14px',
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: sx({
          fontSize: '14px',
          fontWeight: 500,
          color: 'primary.main',
        }),
        secondary: sx({
          fontSize: '12px',
          fontWeight: 700,
          color: 'primary.contrastText',
          lineHeight: 1.2,
        }),
      },
    },
    MuiButton: {
      styleOverrides: {
        endIcon: {
          marginLeft: '4px',
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          '&.Mui-expanded': {
            margin: 0,
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: sx({
          borderRadius: 0,
          transition: 'none',
          bgcolor: 'primary.main',
          minHeight: '58px',
          padding: '0 12px',
          '&.Mui-expanded': {
            minHeight: '58px',
          },
        }),
        content: {
          color: '#ffffff',
          margin: '10px 0',
          '&.Mui-expanded': {
            margin: '10px 0',
          },
        },
        expandIconWrapper: {
          color: '#ffffff',
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: ({ ownerState }) =>
          sx({
            ...(ownerState.color === 'primary' && {
              fontWeight: 'normal',
              textDecorationColor: '#008900',
              bgcolor: 'transparent',
            }),
          }),
      },
    },
  },
});

// eslint-disable-next-line import/no-default-export
export default theme;
