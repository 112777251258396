import * as React from 'react';
import { Box, Button, Typography, List, ListItem } from '@mui/material';
import { MenuLinkButton } from '@/components/button/MenuLinkButton';
import HighlightOffTwoToneIcon from '@mui/icons-material/HighlightOffTwoTone';
import { BranchItem } from '@/services/data/prefectureLinkList';

const fontIcon = (unicode: string) => (
  <Typography
    variant="caption"
    sx={{
      '&::before': {
        content: `"\\${unicode}"`,
        fontSize: '14px',
        fontFamily: 'icomoon',
      },
    }}
  />
);

const HomeIcon = fontIcon('e921');
const RentIcon = (
  <Typography variant="caption" sx={{ py: 0.5, fontWeight: 'bold' }}>
    借りる
  </Typography>
);
const BuyIcon = (
  <Typography variant="caption" sx={{ py: 0.5, fontWeight: 'bold' }}>
    買う
  </Typography>
);
const ResortIcon = fontIcon('e91b');
const AgencyIcon = fontIcon('e929');
const RabbyIcon = fontIcon('e928');

interface MenuItem {
  title: string;
  href: string;
  iconComponent: React.ReactNode;
}

const buildHref = (href: string, branchItem?: BranchItem) => {
  if (branchItem) {
    return `${branchItem.href}${href}${branchItem.prefectureItem?.href}`;
  }
  return href;
};

const getMenuItems = (branchItem?: BranchItem): MenuItem[] => {
  return [
    {
      title: 'TOP',
      href: branchItem ? branchItem.href : '/',
      iconComponent: HomeIcon,
    },
    {
      title: '賃貸マンション・アパート・一戸建て',
      href: buildHref('/rent/home', branchItem),
      iconComponent: RentIcon,
    },
    {
      title: '貸駐車場',
      href: buildHref('/rent/parking', branchItem),
      iconComponent: RentIcon,
    },
    {
      title: '貸店舗',
      href: buildHref('/rent/shop', branchItem),
      iconComponent: RentIcon,
    },
    {
      title: '貸事務所',
      href: buildHref('/rent/office', branchItem),
      iconComponent: RentIcon,
    },
    {
      title: '貸土地',
      href: buildHref('/rent/land', branchItem),
      iconComponent: RentIcon,
    },
    {
      title: '貸ビル･倉庫･その他',
      href: buildHref('/rent/other', branchItem),
      iconComponent: RentIcon,
    },
    {
      title: '売マンション',
      href: buildHref('/buy/mansion', branchItem),
      iconComponent: BuyIcon,
    },
    {
      title: '売一戸建て',
      href: buildHref('/buy/house', branchItem),
      iconComponent: BuyIcon,
    },
    {
      title: '売土地',
      href: buildHref('/buy/land', branchItem),
      iconComponent: BuyIcon,
    },
    {
      title: '売店舗',
      href: buildHref('/buy/shop', branchItem),
      iconComponent: BuyIcon,
    },
    {
      title: '売事務所',
      href: buildHref('/buy/office', branchItem),
      iconComponent: BuyIcon,
    },
    {
      title: '売ビル・一括マンション・その他',
      href: buildHref('/buy/other', branchItem),
      iconComponent: BuyIcon,
    },
    {
      title: 'リゾート物件を探す',
      href: branchItem ? `${branchItem.href}/buy/resort` : '/buy/resort',
      iconComponent: ResortIcon,
    },
    {
      title: '不動産会社を探す',
      href: branchItem ? `${branchItem.href}/agency` : '/agency',
      iconComponent: AgencyIcon,
    },
    {
      title: 'ラビーちゃんの紹介',
      href: 'http://www.zennichi.or.jp/about/rabby/',
      iconComponent: RabbyIcon,
    },
  ];
};

interface Props {
  open: boolean;
  handleMenuClose: () => void;
  branchItem?: BranchItem;
}

export function Menu({ open, handleMenuClose, branchItem }: Props) {
  const menuItems = getMenuItems(branchItem);
  return (
    <Box
      sx={{
        display: open ? 'flex' : 'none',
        flexDirection: 'column',
        alignItems: 'center',
        bgcolor: 'grey.300',
        p: 1.25,
      }}
    >
      <List sx={{ p: 0, width: '100%', borderTop: '1px solid', borderColor: 'grey.400' }}>
        {menuItems.map((item, index) => (
          <ListItem
            key={`menu-${index}`}
            sx={{ bgcolor: 'white', justifyContent: 'space-between', p: 0 }}
          >
            <MenuLinkButton
              title={item.title}
              href={item.href}
              iconComponent={item.iconComponent}
              buttonStyle={{ borderTop: 0, borderColor: 'grey.400' }}
            />
          </ListItem>
        ))}
      </List>

      <Button
        variant="contained"
        startIcon={<HighlightOffTwoToneIcon />}
        onClick={handleMenuClose}
        sx={{ borderRadius: 0, boxShadow: 'none', my: '16px', fontWeight: 'bold' }}
      >
        メニューを閉じる
      </Button>
    </Box>
  );
}
