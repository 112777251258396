import axios from 'axios';

const instance = axios.create({
  baseURL: process.env['NEXT_PUBLIC_API_URL'] || process.env['NEXT_PUBLIC_BASE_URL'],
});
export { instance as axios };

export const fetcher = (url: string) => instance.get(url).then((res) => res.data);

/* TODO request/responseの共通処理 */
// // Add a request interceptor
// axios.interceptors.request.use(function (config) {
//   // Do something before request is sent
//   return config;
// }, function (error) {
//   // Do something with request error
//   return Promise.reject(error);
// });
//
// // Add a response interceptor
// axios.interceptors.response.use(function (response) {
//   // Any status code that lie within the range of 2xx cause this function to trigger
//   // Do something with response data
//   return response;
// }, function (error) {
//   // Any status codes that falls outside the range of 2xx cause this function to trigger
//   // Do something with response error
//   return Promise.reject(error);
// });
