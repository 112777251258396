import * as React from 'react';
import Image from 'next/image';
import {
  Box,
  Button,
  Grid,
  Typography,
  TextField,
  Link,
  List,
  ListItem,
  GridProps,
} from '@mui/material';
import { styled, experimental_sx as sx } from '@mui/material/styles';
import navtitleRent from '@/public/navtitle-rent.png';
import navtitleSale from '@/public/navtitle-sale.png';
import resortIcon from '@/public/resort-icon.png';
import searchIcon01 from '@/public/search-icon01.png';
import searchIcon02 from '@/public/search-icon02.png';
import labbySelf from '@/public/labby-self.png';
import labbyBody from '@/public/labby-body.png';
import labbyLink from '@/public/labby-link.png';
import { NavItem, NavLinkButton } from '@/components/button/NavLinkButton';
import { buyCategoryDataSet, rentCategoryDataSet } from '@/services/data/propertyCategoryList';
import { BranchItem } from '@/services/data/prefectureLinkList';
import { useRef } from 'react';
import { propertyClassificationById } from '@/services/utils/propertyClassification';
import { useRouter } from 'next/router';
import { MessageDialog } from '@/components/dialog/MessageDialog';

const agencyNavItems: NavItem[] = [
  {
    title: '地域から探す',
    href: '/agency/area',
    imageData: searchIcon01,
  },
  {
    title: '沿線/駅から探す',
    href: '/agency/line',
    imageData: searchIcon02,
  },
];

const agencyNavItem: NavItem[] = [
  {
    title: '地域から探す',
    href: '/agency/area',
    imageData: searchIcon01,
  },
];

const SearchIconWrap = styled(Box)(() => ({
  position: 'relative',
  bgcolor: 'primary.light',
  width: '75px',
  minWidth: '75px',
  height: '75px',
  minHeight: '75px',
}));

const NavTitleH2 = styled(Typography)(() => ({
  fontWeight: 'bold',
  textAlign: 'center',
  fontSize: '36px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-end',
  columnGap: '8px',
  marginBottom: '16px',
}));

const NavTitleH3 = styled(Typography)(() => ({
  fontWeight: 'bold',
  textAlign: 'center',
}));

const SearchTextField = styled(TextField)(() => ({
  width: '100%',
  '& .MuiOutlinedInput-root': {
    backgroundColor: 'white',
    '& input': {
      padding: '4px 6px 5px',
      fontSize: '12px',
      color: '#000000',
    },
  },
}));

const GridItem = styled((props: GridProps) => <Grid {...props} item xs={12} />)(({ theme }) =>
  sx({
    p: 1,
    border: '1px solid',
    borderColor: 'grey.400',
    boxShadow: `1px 1px 1px 1px ${theme.palette.primary.light}`,
  }),
);

interface Props {
  branchItem?: BranchItem;
}

export function DivNav({ branchItem }: Props) {
  const buildPrefectureHref = (href: string) => {
    if (branchItem) {
      return `${branchItem.href}${href}${branchItem.prefectureItem?.href}`;
    }
    return href;
  };

  const buildOtherHref = (href: string) => {
    if (branchItem) {
      return `${branchItem.href}${href}`;
    }
    return href;
  };
  const agencyNavList = Boolean(branchItem?.cityCodes.length) ? agencyNavItem : agencyNavItems;

  const router = useRouter();
  const agencySearchText = useRef('');
  // 03/13 物件番号検索追加から
  const propertySearchNumber = useRef('');
  const [dialogMessage, setDialogMessage] = React.useState('');
  const [messageDialogOpen, setMessageDialog] = React.useState(false);

  // [x]: 検索失敗時のダイアログ追加
  const routePushByPropertyId = async (value: string) => {
    const validation = /^[0-9]+$/.test(value);
    if (!validation) {
      setDialogMessage('半角数字で入力して下さい。');
      setMessageDialog(!validation);
      return;
    }

    const propertyClass = await propertyClassificationById(value);
    if (!propertyClass) {
      setDialogMessage('物件が見つかりませんでした。');
      setMessageDialog(true);
      return;
    }

    if ('timeout' in propertyClass) {
      setDialogMessage(
        '現在アクセスが集中しており検索に失敗しました。しばらく時間をおいてから再度お試し下さい。',
      );
      setMessageDialog(true);
      return;
    }

    router.push({ pathname: `${branchItem?.href}/${propertyClass?.path}/${value}` });
  };

  const AgencySearchMethod = (inputText: string) => {
    if (!inputText) {
      router.push(`${buildPrefectureHref('/agency')}`);
      return;
    }
    router.push({ pathname: `${branchItem?.href}/agency/syogo`, query: { syogo: inputText } });
  };

  return (
    <Box
      sx={{
        backgroundImage: `url('/top-nav-background.gif')`,
        backgroundRepeat: 'repeat',
        minWidth: { xs: 'none', sm: '1000px' },
        pr: { xs: 0, sm: 0, headerBP: 24 },
        display: { xs: 'none', sm: 'block' },
      }}
    >
      <Box
        sx={{
          maxWidth: '800px',
          width: '100%',
          m: '0 auto',
          px: 1,
          backgroundColor: 'white',
        }}
      >
        <Grid container columnSpacing={1} position="relative" minWidth="630px">
          <Grid item container xs={6} gap={1} minWidth="320px">
            <GridItem>
              <NavTitleH2 variant="h2">
                <Image src={navtitleRent} alt="" width="48px" height="44.8px" />
                借りる
              </NavTitleH2>
              <List sx={{ display: 'flex', flexWrap: 'wrap', position: 'relative' }}>
                {rentCategoryDataSet.map((item, index) => (
                  <ListItem key={`rent-nav-${index}`} sx={{ width: '33%', padding: 0 }}>
                    <NavLinkButton
                      title={item.title}
                      href={buildPrefectureHref(item.href)}
                      imageData={item.pcImage}
                      titleStyle={{ color: 'text.primary' }}
                      bgColor="#ffffff"
                      hoverBgColor="rgba(0, 180, 62, 0.98)"
                    />
                  </ListItem>
                ))}
              </List>
            </GridItem>
            <GridItem>
              <Button
                component={Link}
                href={buildOtherHref('/buy/resort')}
                sx={{
                  color: 'text.primary',
                  gap: 1,
                  display: 'flex',
                  alignItems: 'center',
                  height: '100%',
                  borderRadius: 0,
                  '&:hover ': {
                    backgroundColor: 'rgba(0, 180, 62, 0.98)',
                  },
                }}
              >
                <SearchIconWrap>
                  <Image src={resortIcon} alt="" />
                </SearchIconWrap>
                <Typography variant="h3" fontSize="24px" fontWeight="bold">
                  リゾート物件を探す
                </Typography>
              </Button>
            </GridItem>
            <GridItem>
              <Box>
                <NavTitleH3 variant="h3" pb={1.5}>
                  物件番号から探す
                </NavTitleH3>
                <Box display="flex" justifyContent="center" gap={1}>
                  <SearchTextField
                    variant="outlined"
                    placeholder="物件番号を入力"
                    sx={{ width: '200px' }}
                    onChange={(e) => {
                      propertySearchNumber.current = e.target.value;
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') routePushByPropertyId(propertySearchNumber.current);
                    }}
                  />
                  <Button
                    size="small"
                    variant="contained"
                    color="accent"
                    sx={{
                      fontWeight: 'bold',
                      lineHeight: 1.2,
                      padding: '4px 8px',
                      minWidth: '48px',
                    }}
                    onClick={() => {
                      routePushByPropertyId(propertySearchNumber.current);
                    }}
                  >
                    検索
                  </Button>
                </Box>
              </Box>
            </GridItem>
          </Grid>

          <Grid item container xs={6} gap={1} minWidth="320px">
            <GridItem>
              <NavTitleH2 variant="h2">
                <Image src={navtitleSale} alt="" width="48px" height="44.8px" />
                買う
              </NavTitleH2>
              <List sx={{ display: 'flex', flexWrap: 'wrap', position: 'relative' }}>
                {buyCategoryDataSet.map((item, index) => (
                  <ListItem key={`sale-nav-${index}`} sx={{ width: '33%', padding: 0 }}>
                    <NavLinkButton
                      title={item.title}
                      href={buildPrefectureHref(item.href)}
                      imageData={item.pcImage}
                      titleStyle={{ color: 'text.primary' }}
                      bgColor="#ffffff"
                      hoverBgColor="rgba(0, 180, 62, 0.98)"
                    />
                  </ListItem>
                ))}
              </List>
            </GridItem>
            <GridItem>
              <Box>
                <NavTitleH3 variant="h3">不動産会社を探す</NavTitleH3>
                <List
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    position: 'relative',
                    justifyContent: 'center',
                    p: 0.3,
                  }}
                >
                  {agencyNavList.map((item, index) => (
                    <ListItem key={`sale-nav-${index}`} sx={{ width: '35%', padding: 0 }}>
                      <NavLinkButton
                        {...item}
                        href={`${buildPrefectureHref(`/${item.href.split('/')[1]}`)}/${
                          item.href.split('/')[2]
                        }`}
                        titleStyle={{ color: 'text.primary' }}
                        hoverBgColor="rgba(0, 180, 62, 0.98)"
                        height="110px"
                      />
                    </ListItem>
                  ))}
                </List>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <Typography variant="h4" fontSize="14px" fontWeight="900" mr="8px">
                    お店の名前
                    <br />
                    (商号)から探す
                  </Typography>
                  <Box display="flex" justifyContent="center" gap={1}>
                    <SearchTextField
                      variant="outlined"
                      placeholder="不動産会社を入力"
                      sx={{ width: '180px' }}
                      onChange={(e) => {
                        agencySearchText.current = e.target.value;
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') AgencySearchMethod(agencySearchText.current);
                      }}
                    />
                    <Button
                      size="small"
                      variant="contained"
                      color="accent"
                      sx={{
                        fontWeight: 'bold',
                        lineHeight: 1.2,
                        padding: '4px 8px',
                        minWidth: '48px',
                      }}
                      onClick={() => {
                        AgencySearchMethod(agencySearchText.current);
                      }}
                    >
                      検索
                    </Button>
                  </Box>
                </Box>
              </Box>
            </GridItem>
          </Grid>

          <Box
            sx={{
              position: 'absolute',
              width: '207px',
              top: '30px',
              right: '-200px',
              display: { xs: 'none', sm: 'none', headerBP: 'block' },
            }}
          >
            <Box
              sx={{
                position: 'relative',
                width: '142px',
                height: '112px',
                ml: '20px',
              }}
            >
              <Image src={labbySelf} alt="ラビーネット不動産" />
            </Box>
            <Box sx={{ position: 'relative', width: '207px', height: '289px' }}>
              <Image src={labbyBody} alt="ラビーネット不動産" priority />
            </Box>
            <Box
              sx={{
                position: 'relative',
                width: '107px',
                height: '63px',
                ml: '25px',
                mt: '10px',
              }}
            >
              <Image src={labbyLink} alt="ラビーネット不動産" />
            </Box>
          </Box>
        </Grid>
      </Box>

      <MessageDialog
        isOpen={messageDialogOpen}
        buttonText={'OK'}
        handleClose={() => {
          setMessageDialog(false);
        }}
      >
        <Box sx={{ width: '400px' }}>{dialogMessage}</Box>
      </MessageDialog>
    </Box>
  );
}
