import { useEffect, useState } from 'react';
import { Box, Fade } from '@mui/material';
import Image from 'next/image';
import backToTopImg from '@/public/back-to-top.png';
import backToTopRollover from '@/public/back-to-top-rollover.png';

// displayPoint: -1 で常に表示
export const BackTopButton = ({ displayPoint = 0 }: { displayPoint?: number }) => {
  const [isVisible, setIsVisible] = useState(false);

  const scrollEventHandler = () => {
    if (displayPoint < 0) return;
    window.scrollY > displayPoint ? setIsVisible(true) : setIsVisible(false);
  };

  useEffect(() => {
    if (displayPoint < 0) setIsVisible(true);
    window.addEventListener('scroll', scrollEventHandler);
    return () => window.removeEventListener('scroll', scrollEventHandler);
  }, []);

  return (
    <Fade in={isVisible} timeout={300}>
      <Box
        sx={{
          bottom: 50,
          right: 50,
          position: 'fixed',
          '@media print': {
            display: 'none',
          },
        }}
        onClick={() => {
          window.scrollTo({ top: 0, behavior: 'smooth' });
        }}
      >
        <Box
          sx={{
            width: '106px',
            height: '147px',
            position: 'absolute',
            bottom: 0,
            right: 0,
          }}
        >
          <Image src={backToTopImg} alt="ページの先頭へ" />
        </Box>

        <Box
          sx={{
            width: '106px',
            height: '147px',
            position: 'absolute',
            bottom: 0,
            right: 0,
            opacity: 0,
            transition: 'opacity 0.3s cubic-bezier(.55,0,.1,1)',
            '&:hover': {
              opacity: '1',
            },
          }}
        >
          <Image src={backToTopRollover} alt="" />
        </Box>
      </Box>
    </Fade>
  );
};
