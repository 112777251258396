import { BranchItem } from '@/services/data/prefectureLinkList';
import { Box, List, ListItem } from '@mui/material';
import { styled, experimental_sx as sx } from '@mui/material/styles';
import {
  buyCategoryDataSet,
  rentCategoryDataSet,
  resortCategoryDataSet,
} from '../../services/data/propertyCategoryList';
import { NextLink } from '@/components/link/NextLink';

interface Props {
  navType: 'buy' | 'rent' | 'resort';
  branchItem?: BranchItem;
}

const NavTitleItem = styled(ListItem)(
  sx({
    padding: 0,
    width: 'max-content',
    lineHeight: 1,
  }),
);

const NavListItem = styled(ListItem)(
  sx({
    borderRight: '1px solid',
    borderColor: 'grey.400',
    py: 0.75,
    mb: 0.5,
    mt: 0.25,
    width: 'max-content',
    flexGrow: 1,
    '&:nth-of-type(2)': {
      borderLeft: '1px solid',
      borderColor: 'grey.400',
    },
    '&:hover': {
      bgcolor: 'grey.200',
    },
  }),
);

const NavListLink = styled(NextLink)(
  sx({
    color: 'text.primary',
    textDecoration: 'none',
    width: '100%',
    textAlign: 'center',
  }),
);

export const HeaderNavList: React.VFC<Props> = ({ navType, branchItem }) => {
  const title = navType === 'buy' ? '買う' : navType === 'rent' ? '借りる' : 'リゾート物件';
  const categoryItems =
    navType === 'buy'
      ? buyCategoryDataSet
      : navType === 'rent'
      ? rentCategoryDataSet
      : resortCategoryDataSet;
  return (
    <List sx={{ display: 'flex', width: '1000px', m: '0 auto', p: 0 }}>
      <NavTitleItem>
        <Box
          sx={{
            fontSize: '14px',
            color: '#ffffff',
            fontWeight: 'bold',
            px: 2,
            py: 0.75,
            bgcolor: 'primary.main',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            width: 'max-content',
            mr: 3,
            '&:before': {
              position: 'absolute',
              content: '""',
              border: '4px solid',
              borderColor: 'transparent',
              borderLeftColor: 'primary.main',
              top: '50%',
              right: '16px',
              marginTop: '-4px',
            },
          }}
        >
          {title}
        </Box>
      </NavTitleItem>
      {categoryItems.map((item, index) => (
        <NavListItem key={`header-nav-item-${index}`}>
          <NavListLink href={`${branchItem ? branchItem.href : ''}${item.href}`}>
            {item.title}
          </NavListLink>
        </NavListItem>
      ))}
    </List>
  );
};
