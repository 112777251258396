import * as React from 'react';
import Button from '@mui/material/Button';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Typography from '@mui/material/Typography';
import Box from '@mui/system/Box';
import { SxProps } from '@mui/material';

interface LinkButtonProps {
  title: string;
  href: string;
  iconComponent?: React.ReactNode;
  buttonStyle?: SxProps;
}

export const MenuLinkButton: React.VFC<LinkButtonProps> = ({
  title,
  href,
  iconComponent,
  buttonStyle,
}) => {
  return (
    <Button
      variant="outlined"
      size="large"
      fullWidth
      endIcon={<ChevronRightIcon sx={{ color: 'grey.500' }} />}
      href={href}
      sx={{
        justifyContent: 'space-between',
        borderRadius: 0,
        border: '0.5px solid',
        borderColor: 'grey.500',
        bgcolor: 'white',
        px: '10px',
        ...buttonStyle,
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
        {iconComponent && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              bgcolor: 'primary.main',
              color: 'primary.contrastText',
              width: '50px',
              minWidth: '50px',
              height: '20px',
            }}
          >
            {iconComponent}
          </Box>
        )}
        <Typography
          variant="body1"
          sx={{
            color: 'text.primary',
          }}
        >
          {title}
        </Typography>
      </Box>
    </Button>
  );
};
