import * as React from 'react';
import { List, SxProps } from '@mui/material';
import { LinkListItem, LinkListRow } from './LinkListRow';

interface Props {
  links: LinkListItem[];
  listItemOption: SxProps;
  secondary?: boolean;
}

LinkList.defaultProps = {
  listItemOption: {},
};

export function LinkList(props: Props) {
  return (
    <>
      <List>
        {props.links.map((item) => {
          return (
            <LinkListRow
              link={item}
              key={item.title}
              listItemOption={props.listItemOption}
              secondary={props.secondary}
            />
          );
        })}
      </List>
    </>
  );
}
