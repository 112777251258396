import { Header } from './Header';
import * as React from 'react';
import { Container } from '@mui/material';
import { Footer } from './Footer';
import { breadcrumbItem } from '@/components/link/RabbyBreadcrumbs';
import { BranchItem } from '@/services/data/prefectureLinkList';
import { DivNav } from './DivNav';
import { RealEstateInformation } from '@/lib/swagger';
// eslint-disable-next-line import/no-default-export
export default function DivLayout({
  children,
  pathName,
  breadcrumbs,
  headerNavType,
  branchItem,
  information,
}: {
  children: React.ReactNode;
  pathName?: string;
  breadcrumbs?: breadcrumbItem[];
  headerNavType?: 'rent' | 'buy' | 'resort' | 'agency';
  branchItem?: BranchItem;
  information?: RealEstateInformation;
}) {
  const path = pathName?.split('/');
  const isTopPage = path && path.length === 2;
  return (
    <>
      <Header
        branchItem={branchItem}
        breadcrumbs={breadcrumbs}
        headerNavType={headerNavType}
        infomation={information}
        isTopPage={isTopPage}
      />
      {isTopPage && <DivNav branchItem={branchItem} />}
      <Container
        maxWidth="md"
        disableGutters={true}
        sx={{ minWidth: { xs: 'none', sm: '1000px' } }}
      >
        {/*TODO params*/}
        <main>{children}</main>
      </Container>
      <Footer branchItem={branchItem} />
    </>
  );
}
