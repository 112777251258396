import * as React from 'react';
import Image from 'next/image';
import { Box, Grid, Link, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { LinkListItem } from '@/components/list/LinkListRow';
import { LinkList } from '@/components/list/LinkList';
import mark from '@/public/mark.png';
import logo from '@/public/logo.png';
import labby from '@/public/footer-labby.png';
import { BranchItem } from '@/services/data/prefectureLinkList';

const buildPrefectureHref = (href: string, branchItem?: BranchItem) => {
  if (branchItem) {
    return `${branchItem.href}${href}${branchItem.prefectureItem?.href}`;
  }
  return href;
};

const buildOtherHref = (href: string, branchItem?: BranchItem) => {
  if (branchItem) {
    return `${branchItem.href}${href}`;
  }
  return href;
};

const getFooterItems3 = (
  branchItem?: BranchItem,
): { category: string; linkListItems: LinkListItem[] }[] => {
  return [
    {
      category: '借りる（賃貸）',
      linkListItems: [
        {
          title: '賃貸マンション・アパート・一戸建て',
          href: buildPrefectureHref('/rent/home', branchItem),
        },
        {
          title: '貸駐車場',
          href: buildPrefectureHref('/rent/parking', branchItem),
        },
        { title: '貸店舗', href: buildPrefectureHref('/rent/shop', branchItem) },
        {
          title: '貸事務所',
          href: buildPrefectureHref('/rent/office', branchItem),
        },
        { title: '貸土地', href: buildPrefectureHref('/rent/land', branchItem) },
        {
          title: '貸ビル・倉庫・その他',
          href: buildPrefectureHref('/rent/other', branchItem),
        },
      ],
    },
    {
      category: '買う',
      linkListItems: [
        {
          title: '売マンション',
          href: buildPrefectureHref('/buy/mansion', branchItem),
        },
        {
          title: '売一戸建て',
          href: buildPrefectureHref('/buy/house', branchItem),
        },
        { title: '売店舗', href: buildPrefectureHref('/buy/shop', branchItem) },
        { title: '売事務所', href: buildPrefectureHref('/buy/office', branchItem) },
        { title: '売土地', href: buildPrefectureHref('/buy/land', branchItem) },
        {
          title: '売ビル・一括マンション・その他',
          href: buildPrefectureHref('/buy/other', branchItem),
        },
      ],
    },
    {
      category: 'リゾート物件',
      linkListItems: [
        {
          title: 'リゾートマンション',
          href: buildOtherHref('/buy/resort/mansion', branchItem),
        },
        {
          title: '別荘・保養所',
          href: buildOtherHref('/buy/resort/villa', branchItem),
        },
        {
          title: '別荘地・土地',
          href: buildOtherHref('/buy/resort/land', branchItem),
        },
      ],
    },
    {
      category: '不動産会社を探す',
      linkListItems: [
        {
          title: 'エリアから探す',
          href: `${buildPrefectureHref('/agency', branchItem)}/area`,
        },
        {
          title: '沿線 / 駅から探す',
          href: `${buildPrefectureHref('/agency', branchItem)}/line`,
        },
      ],
    },
  ];
};

const footerItem4: LinkListItem[] = [
  {
    title: 'サイトマップ',
    href: '/sitemap',
  },
  {
    title: '個人情報保護方針',
    href: '/kyokai/privacypolicy',
  },
  {
    title: '個人情報のお取扱いについて',
    href: '/kyokai/privacyinfo',
  },
  {
    title: '免責事項',
    href: '/kyokai/responsibility',
  },
  {
    title: 'リンクとバナーについて',
    href: '/linkpolicy',
  },
];

const footerItem5: LinkListItem[] = [
  {
    title: '全日本不動産協会について',
    href: 'http://www.zennichi.or.jp/zennichi/zennichi_top.php',
  },
  {
    title: '不動産保証協会について',
    href: 'https://www.fudousanhosho.or.jp/',
  },
  {
    title: '所在地のご案内',
    href: 'http://www.zennichi.or.jp/chihou/chihou_top.php',
  },
  {
    title: '不動産会社情報',
    href: 'https://www.zennichi-navi.jp/',
  },
  {
    title: '入会のご案内',
    href: 'http://www.zennichi.or.jp/practice/admission/',
  },
];

const footerItemSP: LinkListItem[] = [
  {
    title: 'サイトマップ',
    href: '/sitemap',
  },
  {
    title: '個人情報保護方針',
    href: '/kyokai/smt/privacypolicy',
  },
  {
    title: '個人情報のお取扱いについて',
    href: '/kyokai/smt/privacyinfo',
  },
  {
    title: '免責事項',
    href: '/kyokai/smt/responsibility',
  },
];

const SubTitle = styled(Typography)(({ theme }) => ({
  borderLeft: `5px solid ${theme.palette.primary.main}`,
  paddingLeft: 10,
  marginBottom: 12,
  fontSize: '16px',
}));

interface Props {
  branchItem?: BranchItem;
}
export function Footer({ branchItem }: Props) {
  const footerItems3 = getFooterItems3(branchItem);
  return (
    <footer>
      <Box
        sx={{
          typography: 'body2',
          bgcolor: '#333333',
          py: 2.25,
          pl: 1.5,
          display: { xs: 'none', sm: 'block' },
          minWidth: { xs: 'none', sm: '1000px' },
        }}
      >
        <Box maxWidth="1000px" m="0 auto">
          <SubTitle variant="h2" color="white" fontWeight="bold">
            物件・不動産を探す
          </SubTitle>
          <Grid container direction="column" spacing={1}>
            <Grid item container direction="column" spacing={1}>
              {footerItems3.map((item, index) => (
                <Grid item container xs="auto" key={`search-category-${index}`} mb={0.25}>
                  <Grid item xs="auto" sx={{ pr: 2, display: 'flex', alignItems: 'center' }}>
                    <Typography
                      variant="body2"
                      sx={{
                        width: '100px',
                        color: '#ffffff',
                        fontWeight: 'bold',
                      }}
                    >
                      {item.category}
                    </Typography>
                  </Grid>
                  {item.linkListItems.map((prefectureLink, index) => (
                    <Grid
                      item
                      xs="auto"
                      key={`search-category-item-${index}`}
                      sx={{ px: 1.25, borderLeft: '1px solid #ffffff' }}
                    >
                      <Link
                        href={prefectureLink.href}
                        sx={{ color: '#ffffff', textDecorationColor: '#ffffff' }}
                      >
                        <Typography variant="body2" fontWeight="bold">
                          {prefectureLink.title}
                        </Typography>
                      </Link>
                    </Grid>
                  ))}
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box
        sx={{
          typography: 'body2',
          bgcolor: 'white',
          py: 1.5,
          px: 1.5,
          display: { xs: 'none', sm: 'block' },
          minWidth: { xs: 'none', sm: '1000px' },
        }}
      >
        <Box maxWidth="1000px" m="0 auto">
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <Box sx={{ position: 'relative', width: '56px', height: '54px' }}>
              <Image src={mark} alt="" />
            </Box>
            <Grid container direction="column" alignItems="flex-end">
              <Grid item container sx={{ width: 'max-content', mb: 0.5 }}>
                {footerItem4.map((item, index) => (
                  <Grid item xs="auto" key={`link-item-1-${index}`} sx={{ px: 1.25 }}>
                    <Link target="_blank" href={item.href} color="text.primary">
                      <Typography variant="body2">{item.title}</Typography>
                    </Link>
                  </Grid>
                ))}
              </Grid>
              <Grid item container sx={{ width: 'max-content', mb: 0.5 }}>
                {footerItem5.map((item, index) => (
                  <Grid item xs="auto" key={`link-item-2-${index}`} sx={{ px: 1.25 }}>
                    <Link target="_blank" href={item.href} color="text.primary">
                      <Typography variant="body2">{item.title}</Typography>
                    </Link>
                  </Grid>
                ))}
              </Grid>
              <Grid item>
                <Typography variant="caption">
                  Copyright(c) Zennichi. All Rights Reserved.
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          typography: 'body2',
          bgcolor: 'white',
          pt: 2.5,
          pb: 1,
          display: { xs: 'block', sm: 'none' },
          position: 'relative',
        }}
      >
        <Box maxWidth="1000px" m="0 auto">
          <Box
            sx={{
              background: `url('/footer-back-img.png') repeat-x left bottom / contain`,
              bgcolor: 'primary.main',
              px: 1.5,
              pb: '78px',
            }}
          >
            <LinkList
              links={footerItemSP}
              secondary
              listItemOption={{ p: 0.125, width: 'max-content', zIndex: 100 }}
            />
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              px: 1.5,
              py: 1,
            }}
          >
            <Box
              sx={{
                position: 'relative',
                width: 114.5,
                height: 39.5,
                my: 1,
                zIndex: 100,
              }}
            >
              <Image src={logo} alt="ラビーネット不動産" />
            </Box>
            <Box sx={{ position: 'relative', width: '56px', height: '54px' }}>
              <Image src={mark} alt="" />
            </Box>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <Typography variant="caption">Copyright(c) Zennichi. All Rights Reserved.</Typography>
          </Box>
        </Box>
        <Box sx={{ position: 'absolute', top: '-2px', right: '14px', zIndex: 90 }}>
          <Box sx={{ position: 'relative', width: '172px', height: '264px' }}>
            <Image src={labby} alt="" />
          </Box>
        </Box>
      </Box>
    </footer>
  );
}
