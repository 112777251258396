import * as React from 'react';
import { ListItem, ListItemText, SxProps, Link, ListItemIcon } from '@mui/material';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

interface Props {
  link: LinkListItem;
  listItemOption: SxProps;
  secondary?: boolean;
}

export interface LinkListItem {
  title: string;
  href: string;
  icon?: boolean;
  iconStyle?: SxProps;
  isSub?: boolean;
}

export function LinkListRow(props: Props) {
  const item = props.link;

  const { listItemOption = {} } = props;
  const listItemTextProps = props.secondary ? { secondary: item.title } : { primary: item.title };

  return (
    <ListItem sx={listItemOption}>
      <ListItem component={Link} href={item.href} target="_blank" key={item.title} sx={{ p: 0 }}>
        {item.icon && (
          <ListItemIcon sx={{ minWidth: 0, mr: 1 }}>
            <ChevronRightIcon sx={item.iconStyle} />
          </ListItemIcon>
        )}
        <ListItemText {...listItemTextProps} />
      </ListItem>
    </ListItem>
  );
}
