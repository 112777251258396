import type { NextPage } from 'next';
import { Box, Link, Typography } from '@mui/material';
import Head from 'next/head';
import Image from 'next/image';
import { useViewSize } from '@/services/hooks/useViewSize';
import { useRouter } from 'next/router';

const Custom504: NextPage = () => {
  const { isMobileSize } = useViewSize();
  const router = useRouter();

  return (
    <div>
      <Head>
        <title>【ラビーネット不動産】</title>
        <meta name="description" content=""></meta>
      </Head>
      <Box
        maxWidth="md"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minHeight: '500px',
          width: '100%',
          py: [0, 5],
        }}
      >
        <Typography
          variant="h3"
          sx={{
            fontSize: isMobileSize ? '14px' : '28px',
            color: isMobileSize ? 'primary.light' : 'text.primary',
            borderBottom: isMobileSize ? '2px solid' : '5px solid',
            borderTop: isMobileSize ? '2px solid' : 'none',
            borderColor: 'primary.light',
            pt: isMobileSize ? '14px' : 0,
            pb: '14px',
            px: isMobileSize ? '10px' : 0,
            mb: '19px',
            width: '100%',
          }}
        >
          しばらくお待ち下さい
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'center' }}>
          <Box sx={{ width: '80%' }}>
            <Typography sx={{ lineHeight: 1.8, mb: isMobileSize ? 0 : '20px' }}>
              この度はラビーネット不動産をご利用いただきありがとうございます。
              <br />
              現在、アクセスが集中しており、ご希望のページを表示できません。
              <br />
              恐れ入りますが、しばらく時間をおいてから、再度アクセスをお試しください。
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'flex-end' }}>
              <Link
                onClick={() => {
                  router.back();
                }}
                sx={{ py: isMobileSize ? '14px' : 0, cursor: 'pointer' }}
              >
                戻る
              </Link>
              {isMobileSize && (
                <Box sx={{ position: 'relative', width: '88px', height: '95.5px' }}>
                  <Image src="/sorry_rabby.png" layout="fill" alt="残念なラビーちゃん" />
                </Box>
              )}
            </Box>
          </Box>
          {!isMobileSize && (
            <Box sx={{ position: 'relative', width: '200px', height: '197px' }}>
              <Image src="/sorry_rabby.png" layout="fill" alt="残念なラビーちゃん" />
            </Box>
          )}
        </Box>
      </Box>
    </div>
  );
};

// eslint-disable-next-line import/no-default-export
export default Custom504;
