import Image, { StaticImageData } from 'next/image';
import { SxProps, Typography } from '@mui/material';
import NextLink from 'next/link';
import MuiLink from '@mui/material/Link';

interface Props {
  title: string;
  href: string;
  imageData?: StaticImageData;
  imageHeight?: string;
  imageWidth?: string;
  textStyles?: SxProps;
  isActive?: boolean;
}

const hoverStyle = {
  bgcolor: 'primary.main',
  color: '#ffffff',
  '&:before': {
    content: '""',
    position: 'absolute',
    top: '100%',
    left: '50%',
    ml: '-4px',
    border: '4px solid',
    borderColor: 'transparent',
    borderTopColor: 'primary.main',
  },
};

const isActiveStyle = {
  display: 'flex',
  alignItems: 'center',
  textDecoration: 'none',
  p: '16px 8px',
  position: 'relative',
  ...hoverStyle,
  '&:hover': hoverStyle,
};

const noneActiveStyle = {
  display: 'flex',
  alignItems: 'center',
  color: 'text.primary',
  textDecoration: 'none',
  p: '16px 8px',
  position: 'relative',
  '&:hover': hoverStyle,
};

export const BalloonLink: React.VFC<Props> = ({
  title,
  href,
  imageData,
  imageHeight,
  imageWidth,
  textStyles,
  isActive,
}) => {
  return (
    <NextLink href={href}>
      <MuiLink href={href} sx={isActive ? isActiveStyle : noneActiveStyle}>
        {imageData && (
          <Image
            src={imageData}
            width={imageHeight || '36px'}
            height={imageWidth || '34px'}
            alt=""
          />
        )}
        <Typography
          component="span"
          sx={{ fontWeight: 'bold', fontSize: '16px', ml: 0.5, ...textStyles }}
        >
          {title}
        </Typography>
      </MuiLink>
    </NextLink>
  );
};
