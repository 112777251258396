import axios, { AxiosResponse } from 'axios';

export interface PropertyClass {
  property_type: string;
  property_deal_type: 'sale' | 'rent';
  offer_use_type: 'residential' | 'business';
  property_detail_type: string;
  is_resort_mansion: boolean;
}

const propertyDetailTypeList: { [key: string]: string[] } = {
  'rent/shop': ['store', 'store_and_office', 'store_with_house'],
  'rent/office': ['office', 'store_and_office'],
  'rent/other': [
    'detached_house',
    'terraced_house',
    'town_house',
    'mansion',
    'apartment',
    'building',
    'share_house',
    'factory',
    'warehouse',
    'hotel',
    'ryokan',
    'dormitory',
    'villa',
    'other',
  ],
  'buy/mansion': ['mansion', 'apartment', 'terraced_house', 'town_house'],
  'buy/house': ['detached_house'],
  'buy/shop': ['store', 'store_and_office', 'store_with_house', 'house_with_store'],
  'buy/office': ['office', 'store_and_office'],
  'buy/resort/villa': ['villa'],
  'buy/resort/land': ['villa', 'recreation_center'],
};

export interface PropertyRabbynetClass {
  path: string;
  // type: string;
  // category: string;
}

export const propertyClassificationById: (
  propertyId: string,
) => Promise<PropertyRabbynetClass | { timeout: true } | undefined> = async (propertyId) => {
  try {
    const res = await axios.get<any, AxiosResponse<PropertyClass>>(
      `/api/getPropertyClass/${propertyId}`,
    );

    const {
      property_type,
      property_deal_type,
      offer_use_type,
      property_detail_type,
      is_resort_mansion,
    } = res.data;

    // rent/home
    if (property_deal_type === 'rent' && property_type === 'room') return { path: 'rent/home' };

    // rent/parking
    if (property_deal_type === 'rent' && property_type === 'parking_space')
      return { path: 'rent/parking' };

    // rent/shop
    if (
      property_deal_type === 'rent' &&
      offer_use_type === 'business' &&
      propertyDetailTypeList['rent/shop']?.includes(property_detail_type)
    )
      return { path: 'rent/shop' };

    // rent/office
    if (
      property_deal_type === 'rent' &&
      offer_use_type === 'business' &&
      propertyDetailTypeList['rent/office']?.includes(property_detail_type)
    )
      return { path: 'rent/office' };

    // rent/land
    if (property_deal_type === 'rent' && offer_use_type === 'business' && property_type === 'land')
      return { path: 'rent/land' };

    // rent/other
    if (
      property_deal_type === 'rent' &&
      offer_use_type === 'business' &&
      propertyDetailTypeList['rent/other']?.includes(property_detail_type)
    )
      return { path: 'rent/other' };

    // buy/mansion
    if (
      property_deal_type === 'sale' &&
      property_type === 'room' &&
      propertyDetailTypeList['buy/mansion']?.includes(property_detail_type) &&
      !is_resort_mansion
    )
      return { path: 'buy/mansion' };

    // buy/house
    if (
      property_deal_type === 'sale' &&
      property_type === 'room' &&
      propertyDetailTypeList['buy/house']?.includes(property_detail_type)
    )
      return { path: 'buy/house' };

    // buy/shop
    if (
      property_deal_type === 'sale' &&
      offer_use_type === 'business' &&
      propertyDetailTypeList['buy/shop']?.includes(property_detail_type)
    )
      return { path: 'buy/shop' };

    // buy/office
    if (
      property_deal_type === 'sale' &&
      offer_use_type === 'business' &&
      propertyDetailTypeList['buy/office']?.includes(property_detail_type)
    )
      return { path: 'buy/office' };

    // buy/land
    if (property_deal_type === 'sale' && property_type === 'land') return { path: 'buy/land' };

    // buy/other
    // TODO: 条件すり合わせ
    // if (
    //   property_deal_type === 'sale'
    // ) return { path: 'buy/other'};

    // buy/resort/villa
    if (
      property_deal_type === 'sale' &&
      property_type === 'room' &&
      propertyDetailTypeList['buy/resort/villa']?.includes(property_detail_type)
    )
      return { path: 'buy/resort/villa' };

    // buy/resort/mansion
    // [x]: イタンジ is_resort_mansionのプロパティ追加待ち
    if (property_deal_type === 'sale' && property_type === 'room' && is_resort_mansion)
      return { path: 'buy/resort/mansion' };

    // buy/resort/land
    if (
      property_deal_type === 'sale' &&
      property_type === 'land' &&
      propertyDetailTypeList['buy/resort/land']?.includes(property_detail_type)
    )
      return { path: 'buy/resort/land' };

    return undefined;
  } catch (e: any) {
    if (e.response?.status === 504) return { timeout: true };
    return undefined;
  }
};
