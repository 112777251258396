import { createContext, useContext } from 'react';
import {
  CategoryKey,
  StorageValue,
  useSavePropertiesToLocalStorage,
} from '../hooks/useSavePropatiesToLocalStorage';

type SavedItems = Map<string, StorageValue[]>;
type Count = () => number;
type AddItems = (ids: string[], categoryKey: string | CategoryKey) => void;
type RemoveItems = (ids: string[], categoryKey: string | CategoryKey) => void;
type isSaved = (id: string, categoryKey: string | CategoryKey) => boolean;
type removeAll = () => void;

interface StorageDispatches {
  addFavorites: AddItems;
  removeFavorites: RemoveItems;
  removeFavoritesAll: removeAll;
  addHistories: AddItems;
  removeHistories: RemoveItems;
  removeHistoriesAll: removeAll;
}

interface StorageStates {
  favorites: SavedItems;
  favoritesCount: Count;
  isFavorite: isSaved;
  histories: SavedItems;
  historiesCount: Count;
  isVisited: isSaved;
}

const SaveStorageDispatchesContext = createContext<StorageDispatches | null>(null);
const SaveStorageStateContext = createContext<StorageStates | null>(null);

export const SavaStorageProvider = ({ children }: { children: React.ReactNode }) => {
  const {
    properties: favorites,
    count: favoritesCount,
    addItems: addFavorites,
    removeItems: removeFavorites,
    isSaved: isFavorite,
    removeAll: removeFavoritesAll,
  } = useSavePropertiesToLocalStorage({ key: 'favorite' });

  const {
    properties: histories,
    count: historiesCount,
    addItems: addHistories,
    removeItems: removeHistories,
    isSaved: isVisited,
    removeAll: removeHistoriesAll,
  } = useSavePropertiesToLocalStorage({ key: 'history' });

  return (
    <SaveStorageDispatchesContext.Provider
      value={{
        addFavorites,
        removeFavorites,
        removeFavoritesAll,
        addHistories,
        removeHistories,
        removeHistoriesAll,
      }}
    >
      <SaveStorageStateContext.Provider
        value={{
          favorites,
          favoritesCount,
          isFavorite,
          histories,
          historiesCount,
          isVisited,
        }}
      >
        {children}
      </SaveStorageStateContext.Provider>
    </SaveStorageDispatchesContext.Provider>
  );
};

export const useSaveStorageStates = () => {
  const states = useContext(SaveStorageStateContext);
  if (!states) throw new Error('');
  return states;
};

export const useSaveStorageDispatches = () => {
  const dispatches = useContext(SaveStorageDispatchesContext);
  return dispatches;
};

export const useSaveStorage = () => {
  return [useSaveStorageStates(), useSaveStorageDispatches()];
};

// TODO: favorite history利用箇所に適用
