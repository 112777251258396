import React from 'react';
import Link from 'next/link';
import { UrlObject } from 'url';
import { Link as MuiLink, styled, SxProps } from '@mui/material';

export const MuiLinkBox = styled(MuiLink)(() => ({
  boxSizing: 'inherit',
  backgroundRepeat: 'no-repeat',
  outline: 0,
  padding: 0,
  margin: 0,
  color: '#009900',
  textDecoration: 'none',
  display: 'inline-block',
  '&::before': {
    textDecoration: 'inherit',
    verticalAlign: 'inherit',
  },
  '&::after': {
    textDecoration: 'inherit',
    verticalAlign: 'inherit',
  },
}));

type LinkProps = {
  href: string | UrlObject;
  children: React.ReactNode;
  target?: string;
  className?: string;
  rel?: string;
  sx?: SxProps;
  as?: string | UrlObject;
};

export const NextLink: React.FC<LinkProps> = (props) => (
  <Link href={props.href} as={props.as} passHref>
    <MuiLinkBox
      target={props.target || '_self'}
      className={props.className}
      rel={props.rel}
      sx={props.sx}
    >
      {props.children}
    </MuiLinkBox>
  </Link>
);
