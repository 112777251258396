import { useLocalStorage } from 'react-use';
import add from 'date-fns/add';
import { Duration, isBefore } from 'date-fns';
import { useEffect, useState } from 'react';

// local storageのkeyは 'favorite' / 'history';

// 保存データはMapオブジェクトに格納
// '[type]/[category]'をkeyとして valueには{id, expires, inserDate}を格納した配列が格納される。
// local storageにはdataTypeとMapオブジェクトの二次配列を格納したオブジェクトをJSONにして格納
// {
//   dataType: 'Map',
//   [
//     ["[type]/[category]", [StorageValue, ...]],
//     ["[type]/[category]", [StorageValue, ...]],
//     ...
//   ]
// }

// addItems, removeItems
// idを格納した string[] 配列とcategoryのKey(string or CategoryKey)を渡す
// 第二引数に渡したのがstringの場合はそのまま、CategoryKeyでは'[type]/[category]'に変換
// resortの場合は buy/resortまたは {type: 'buy/resort', category: [category]}を渡してもらう
// agencyの場合はstringで渡す

// TODO: ここから以下のコメント整理

export interface CategoryKey {
  type: string;
  category: string;
}

export interface StorageValue {
  id: string;
  expires: string;
  insertDate: string;
}

interface Props {
  key: 'favorite' | 'history';
}

const jsonSerializer = (InternalProperties: Map<string, StorageValue[]>) =>
  JSON.stringify(InternalProperties, (_key, value) => {
    if (value instanceof Map) {
      return {
        dataType: 'Map',
        value: [...value],
      };
    }
    return value;
  });

const jsonDeserializer = (value: string): Map<string, StorageValue[]> => {
  return JSON.parse(value, (_key, value) => {
    if (value && typeof value === 'object' && value.dataType === 'Map') {
      return new Map<string, StorageValue[]>(value.value);
    }
    return value;
  });
};

function combCategoryKey(categoryKey: string | CategoryKey): string {
  return typeof categoryKey == 'string'
    ? categoryKey
    : categoryKey.type + '/' + categoryKey.category;
}

export const useSavePropertiesToLocalStorage = (props: Props) => {
  // [ ]: XXXのコメント後で消す
  // XXX: ここでアクセスするlocal storageは props.keyのfavorite or history
  // 保存されているデータ(オブジェクト) { dataType: 'Map', value: [["buy/manstion", [ favoriteObject ]],] }
  // favorite object
  // {"id":"31597445", "expires":"Sun Apr 16 2023 04:34:59 GMT+0900 (日本標準時)", "insertDate":"Fri Mar 17 2023 04:34:59 GMT+0900 (日本標準時)"}

  // こうしたかった？
  // { dataType: 'Map', value: [["buy/manstion", [ favoriteObject ]], [["buy/house", [ favoriteObject ]],] }
  // 新しいMapオブジェクトを作成する際に別のkeyのオブジェクトが格納されていない

  const expireDuration: Duration = { days: 30 };
  const limitNum = 50;

  const [storedItems, setStoredItems, remove] = useLocalStorage(
    props.key,
    new Map<string, StorageValue[]>(),
    {
      raw: false,
      serializer: jsonSerializer,
      deserializer: jsonDeserializer,
    },
  );

  const [properties, setProperties] = useState(new Map<string, StorageValue[]>(storedItems));
  const [expiresChecked, setExpiresChecked] = useState(false);

  // 保存したItemの期限チェック
  useEffect(() => {
    if (!storedItems) return;

    const updateStoredItems = new Map<string, StorageValue[]>(storedItems);
    updateStoredItems.forEach((strageValues, key) => {
      const filterValues = strageValues.filter((strageValue) =>
        isBefore(new Date(), new Date(strageValue.expires)),
      );
      updateStoredItems.set(key, filterValues);
    });

    setStoredItems(updateStoredItems);
    setExpiresChecked(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useLocalStorageのsetStoredItems関数でstoredItemsに即時反映されないので監視して更新されたらStateに反映
  useEffect(() => {
    if (storedItems) setProperties(storedItems); // XXX: setProperties呼ばれた段階でlocalStorage更新、 addItemは別カテゴリが空の状態のMapオブジェクトでセットするので別カテゴリがリセットされる。
  }, [storedItems]);

  const addItems = (ids: string[], categoryKey: string | CategoryKey) => {
    const key = combCategoryKey(categoryKey); // XXX: ここはstorageKeyというよりcategory key(またはMap key)のほうが適切
    const now = new Date();
    const expires = add(now, expireDuration).toString();
    const insertDate = now.toString();
    const newItems: StorageValue[] = ids.map((id) => ({ id, expires, insertDate }));
    const updateStoredItems = new Map<string, StorageValue[]>(storedItems); // XXX: ここで元のデータを渡す必要がある？

    const oldItems = updateStoredItems.get(key); // XXX: ここでMapオブジェクトから取得されるのはカテゴリごとのfavo list

    if (oldItems) {
      updateStoredItems.set(
        key,
        oldItems
          .filter((v) => !ids.includes(v.id))
          .concat(newItems)
          .slice(-limitNum),
      );
    } else {
      updateStoredItems.set(key, newItems.slice(-limitNum));
    }
    setStoredItems(updateStoredItems); // XXX:更新したカテゴリ以外のデータが空の状態なのをセットしている。
  };
  // XXX: 結論 新しいMapオブジェクトを作成してlocalStorageにセットしているので 新しいMapオブジェクト作成する際に元のMapオブジェクトのデータを渡す必要がある。
  // Map内の参照引き継がないようにディープコピー作る必要がある？
  // 一度保存前に最新のlocalstorageロードしないと 別ページ開く→保存 が上書きされる
  // 追加時配列末に追加されている？
  // 保存制限を超えたものは -limitNumでsliceされている(-50) 末尾から50件残して
  // 表示順って新しい保存が上だっけ下だっけ
  // 元サイト => 保存した順日が古い順で並んでる
  // useStateはオブジェクトに対してどこまで変化を追跡するか
  // 同じオブジェクト渡しても変化と見なさない

  const removeItems = (ids: string[], categoryKey: string | CategoryKey) => {
    const key = combCategoryKey(categoryKey);

    if (!storedItems) return;

    // XXX: ここも元のデータからMapオブジェクトつくってないので 削除すると他のカテゴリリセットされる。
    const updateStoredItems = new Map<string, StorageValue[]>(storedItems);
    const oldItems = updateStoredItems.get(key);
    if (!oldItems) return;

    const filteredItems = oldItems.filter((item) => !ids.includes(item.id));
    updateStoredItems.set(key, filteredItems);
    setStoredItems(updateStoredItems);
  };

  // storage key('favorite' / 'hisotry')に対応するデータ削除
  const removeAll = () => remove();

  // 表示に関連する物はproperties(State)を利用
  const isSaved = (id: string, categoryKey: string | CategoryKey) => {
    if (!properties) return false;
    const storageValues = properties.get(combCategoryKey(categoryKey));
    if (!storageValues) return false;
    return Boolean(storageValues.filter((storageValue) => storageValue.id === id).length);
  };

  const count = () => {
    if (!properties) return 0;
    return Array.from(properties.values()).reduce(function (sum, items) {
      return sum + items.length;
    }, 0);
  };

  return {
    properties,
    count,
    addItems,
    removeItems,
    isSaved,
    removeAll,
    expiresChecked,
  };
};
