import * as React from 'react';
import Image from 'next/image';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import NotesIcon from '@mui/icons-material/Notes';
import {
  Badge,
  Box,
  Button,
  Divider,
  Link,
  List,
  ListItem,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useTheme, styled } from '@mui/material/styles';
import StarIcon from '@mui/icons-material/Star';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import logo from '../../public/logo.png';
import logoIcon from '../../public/logo-icon.png';
import logoIconSP from '../../public/logo-icon_sp.png';
import hnav01 from '../../public/hnav01.png';
import hnav02 from '../../public/hnav02.png';
import hnav03 from '../../public/hnav03.png';
import hnav04 from '../../public/hnav04.png';
import { Menu } from './Menu';
import { breadcrumbItem, RabbyBreadcrumbs } from '../../components/link/RabbyBreadcrumbs';
import { BalloonLink } from '../../components/link/BalloonLink';
import { HeaderNavList } from './HeaderNavList';
import { RealEstateInformation } from '@/lib/swagger';
import { NextLink } from '@/components/link/NextLink';
import { useSaveSearchCondToLocalStorage } from '@/services/hooks/useSaveSearchCondToLocalStorage';
import { useSavePropertiesToLocalStorage } from '@/services/hooks/useSavePropatiesToLocalStorage';
import { useSaveItemsToLocalStorage } from '@/services/hooks/useSaveItemsToLocalStorage';
import { useEffect } from 'react';
import { useRouter } from 'next/router';
// import { useSaveStorageStates } from '@/services/context/SaveStorageProvider';

const Item = styled(Button)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: 'center',
  width: 72,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  rowGap: 6,
}));

const ItemForSP = styled(Button)(({ theme }) => ({
  textAlign: 'center',
  width: '33.33%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: theme.palette.primary.main,
  borderRadius: 0,
}));

const Count = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: 'center',
  fontWeight: 'bold',
  color: theme.palette.accent.main,
  position: 'relative',
}));

const StyledBadge = styled(Badge)(() => ({
  '& .MuiBadge-badge': {
    right: -4,
    top: 12,
    fontWeight: 'bold',
    fontSize: '10.5px',
    padding: '0 4px',
    minWidth: 25,
    height: 25,
    borderRadius: '50%',
    lineHeight: '25px',
  },
}));

const StackTypography = styled(Typography)(() => ({
  textAlign: 'center',
  lineHeight: 1.2,
}));

const LogoSection: React.VFC = () => {
  return (
    <Button component={Link} href="/" display="flex" alignItems="flex-end" sx={{ p: [0, 1] }}>
      <Box
        sx={{
          position: 'relative',
          width: [114.5, 186],
          height: [39.5, 64],
          my: [0, 1],
        }}
      >
        <Image src={logo} alt="ラビーネット不動産" />
      </Box>
      <Box
        sx={{
          position: 'relative',
          width: 47.5,
          height: 70,
          mb: 0.5,
          display: { xs: 'none', sm: 'block' },
        }}
      >
        <Image src={logoIcon} alt="ラビーネット不動産" width="47.5" height="70" />
      </Box>
      <Box
        sx={{
          position: 'relative',
          width: 43,
          height: 50,
          ml: 0.5,
          mb: -0.5,
          display: { xs: 'block', sm: 'none' },
        }}
      >
        <Image src={logoIconSP} alt="ラビーネット不動産" width="43" height="50" />
      </Box>
    </Button>
  );
};

export function Header({
  pathName,
  breadcrumbs,
  headerNavType,
  information,
  isTopPage,
}: {
  pathName?: string;
  breadcrumbs?: breadcrumbItem[];
  headerNavType?: 'buy' | 'rent' | 'resort' | 'agency';
  information?: RealEstateInformation;
  isTopPage?: boolean;
}) {
  const router = useRouter();
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const { count: savedCondCount } = useSaveSearchCondToLocalStorage();
  const { count: savedPropCount } = useSavePropertiesToLocalStorage({
    key: 'favorite',
  });
  const { count: savedAgenciesCount } = useSaveItemsToLocalStorage({
    key: 'favorite-agency',
  });
  const { count: visitedPropCount } = useSavePropertiesToLocalStorage({
    key: 'history',
  });
  const { count: visitedAgenciesCount } = useSaveItemsToLocalStorage({
    key: 'agency-history',
  });

  const [open, setOpen] = React.useState(false);

  const [favoriteCount, setFavoriteCount] = React.useState(0);
  const [visitedCount, setVisitedCount] = React.useState(0);

  // TODO: localstorage agency統合後置き換え
  // const { favoritesCount, historiesCount } = useSaveStorageStates();

  useEffect(() => {
    setFavoriteCount(savedPropCount() + savedAgenciesCount);
    setVisitedCount(visitedPropCount() + visitedAgenciesCount);
  }, [savedAgenciesCount, savedPropCount, visitedAgenciesCount, visitedPropCount]);

  const handleMenuClose = () => {
    setOpen(false);
  };
  return (
    <>
      <AppBar position="relative" color="inherit" sx={{ minWidth: { xs: 'none', sm: '1000px' } }}>
        <Toolbar
          disableGutters={true}
          sx={{
            pl: { xs: 1.25, sm: 0 },
            justifyContent: 'space-between',
            width: '100%',
            maxWidth: 'md',
            minWidth: { xs: 'none', sm: '1000px' },
            m: '0 auto',
            borderBottom: { xs: '1px solid', sm: 'none' },
            borderColor: { xs: 'primary.light' },
          }}
        >
          {isTopPage ? (
            <Typography component="h1">
              <LogoSection />
            </Typography>
          ) : (
            <LogoSection />
          )}
          {!matches && (
            <IconButton
              size="large"
              edge="start"
              aria-label="menu"
              onClick={() => setOpen(!open)}
              sx={{
                flexDirection: 'column',
                fontSize: '12px',
                color: 'grey.600',
                mt: -1.125,
              }}
            >
              <NotesIcon fontSize="large" />
              <Typography variant="caption" sx={{ lineHeight: 0 }}>
                メニュー
              </Typography>
            </IconButton>
          )}
          {matches && pathName !== '/' && (
            <List>
              <ListItem
                sx={{
                  p: 0,
                  gap: 0.5,
                }}
              >
                <BalloonLink
                  title="借りる"
                  href="/rent"
                  imageData={hnav01}
                  isActive={headerNavType === 'rent'}
                />
                <BalloonLink
                  title="買う"
                  href="/buy"
                  imageData={hnav02}
                  isActive={headerNavType === 'buy'}
                />
                <BalloonLink
                  title="リゾート物件"
                  href="/buy/resort"
                  imageData={hnav03}
                  imageHeight="31px"
                  imageWidth="31px"
                  textStyles={{ maxWidth: '64px', textAlign: 'center', lineHeight: 1 }}
                  isActive={headerNavType === 'resort'}
                />
                <BalloonLink
                  title="不動産会社"
                  href="/agency"
                  imageData={hnav04}
                  imageHeight="31px"
                  imageWidth="31px"
                  textStyles={{ maxWidth: '60px', textAlign: 'center', lineHeight: 1 }}
                  isActive={headerNavType === 'agency'}
                />
              </ListItem>
            </List>
          )}
          {matches && (
            <Stack
              direction="row"
              divider={<Divider orientation="vertical" flexItem />}
              spacing={0.5}
              sx={{
                margin: '4px 0',
                px: '4px',
                borderRight: '1px solid rgba(0, 0, 0, 0.12)',
                borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
              }}
            >
              <NextLink href="/mypage/favorite/bukken/rent/home" sx={{ textDecoration: 'none' }}>
                <Item>
                  <Count>
                    <StarIcon fontSize="large" color="disabled" />
                    <Typography
                      variant="caption"
                      sx={{
                        fontSize: '12px',
                        fontWeight: 'bold',
                        right: '-12px',
                        bottom: '-4px',
                        position: 'absolute',
                      }}
                    >
                      {favoriteCount}件
                    </Typography>
                  </Count>
                  <StackTypography variant="caption" color="text.primary">
                    とりあえず
                    <br />
                    保存リスト
                  </StackTypography>
                </Item>
              </NextLink>
              <NextLink href="/mypage/recently/bukken/rent/home" sx={{ textDecoration: 'none' }}>
                <Item>
                  <Count>
                    <AccessTimeIcon fontSize="large" color="disabled" />
                    <Typography
                      variant="caption"
                      sx={{
                        fontSize: '12px',
                        fontWeight: 'bold',
                        right: '-12px',
                        bottom: '-4px',
                        position: 'absolute',
                      }}
                    >
                      {visitedCount}件
                    </Typography>
                  </Count>
                  <StackTypography variant="caption" color="text.primary">
                    最近見た
                    <br />
                    物件
                  </StackTypography>
                </Item>
              </NextLink>
              <Item onClick={() => router.push('/mypage/cond')}>
                <Count>
                  <ManageSearchIcon fontSize="large" color="disabled" />
                  <Typography
                    variant="caption"
                    sx={{
                      fontSize: '12px',
                      fontWeight: 'bold',
                      right: '-12px',
                      bottom: '-4px',
                      position: 'absolute',
                    }}
                  >
                    {savedCondCount}件
                  </Typography>
                </Count>
                <StackTypography variant="caption" color="text.primary">
                  登録した
                  <br />
                  検索条件
                </StackTypography>
              </Item>
            </Stack>
          )}
        </Toolbar>
        {!matches && <Menu open={open} handleMenuClose={handleMenuClose} />}

        {information && (
          <Box
            display="flex"
            justifyContent="center"
            margin={[0.5, 1.25]}
            sx={{ display: { xs: 'flex', sm: 'none' } }}
          >
            <NextLink
              href={information.url || '#'}
              sx={{
                fontSize: 'small',
                fontWeight: '700',
                WebkitTextDecoration: 'underline',
                textDecoration: 'underline',
                textDecorationColor: 'rgba(1, 87, 155, 0.4)',
                color: '#01579b',
              }}
            >
              {information.title || ''}
            </NextLink>
          </Box>
        )}

        {!matches && (
          <Toolbar sx={{ p: 0 }}>
            <Stack
              direction="row"
              divider={<Divider orientation="vertical" flexItem sx={{ borderColor: 'white' }} />}
              sx={{ width: '100%', bgcolor: 'primary.light' }}
            >
              <ItemForSP
                onClick={() => (window.location.href = '/mypage/favorite/bukken/rent/home')}
              >
                <StyledBadge badgeContent={favoriteCount} showZero color="accent">
                  <StarIcon fontSize="large" sx={{ color: 'white' }} />
                </StyledBadge>
                <StackTypography variant="caption" color="white" fontWeight="600">
                  とりあえず保存リスト
                </StackTypography>
              </ItemForSP>
              <ItemForSP
                onClick={() => (window.location.href = '/mypage/recently/bukken/rent/home')}
              >
                <StyledBadge badgeContent={visitedCount} showZero color="accent">
                  <AccessTimeIcon fontSize="large" sx={{ color: 'white' }} />
                </StyledBadge>
                <StackTypography variant="caption" color="white" fontWeight="600">
                  最近見た物件
                </StackTypography>
              </ItemForSP>
              <ItemForSP onClick={() => (window.location.href = '/mypage/cond')}>
                <StyledBadge badgeContent={savedCondCount} showZero color="accent">
                  <ManageSearchIcon fontSize="large" sx={{ color: 'white' }} />
                </StyledBadge>
                <StackTypography variant="caption" color="white" fontWeight="600">
                  登録した検索条件
                </StackTypography>
              </ItemForSP>
            </Stack>
          </Toolbar>
        )}
      </AppBar>
      {information && (
        <Box
          display="flex"
          justifyContent="center"
          margin={1.5}
          sx={{ display: { xs: 'none', sm: 'flex' }, minWidth: { xs: 'none', sm: '1000px' } }}
        >
          <NextLink
            href={information.url || '#'}
            target="_blank"
            sx={{
              fontSize: 'small',
              fontWeight: '700',
              WebkitTextDecoration: 'underline',
              textDecoration: 'underline',
              textDecorationColor: 'rgba(1, 87, 155, 0.4)',
              color: '#01579b',
            }}
          >
            {information.title || ''}
          </NextLink>
        </Box>
      )}
      {matches && headerNavType && headerNavType != 'agency' && (
        <HeaderNavList navType={headerNavType} />
      )}
      <RabbyBreadcrumbs breadcrumbs={breadcrumbs} />
    </>
  );
}
