// https://nextjs.org/docs/basic-features/layouts

import { Header } from './Header';
import * as React from 'react';
import { Container } from '@mui/material';
import { Footer } from './Footer';
import { HomeNav } from '../home/HomeNav';
import { breadcrumbItem } from '../../components/link/RabbyBreadcrumbs';
import { RealEstateInformation } from '@/lib/swagger';
// eslint-disable-next-line import/no-default-export
export default function Layout({
  children,
  pathName,
  breadcrumbs,
  headerNavType,
  information,
}: {
  children: React.ReactNode;
  pathName?: string;
  breadcrumbs?: breadcrumbItem[];
  headerNavType?: 'buy' | 'rent' | 'resort' | 'agency';
  information?: RealEstateInformation;
}) {
  const isTopPage = pathName == '/';
  return (
    <>
      <Header
        pathName={pathName}
        breadcrumbs={breadcrumbs}
        headerNavType={headerNavType}
        information={information}
        isTopPage={isTopPage}
      />
      {isTopPage && <HomeNav />}
      <Container
        maxWidth="md"
        disableGutters={true}
        sx={{ minWidth: { xs: 'none', sm: '1000px', fontSize: '14px' } }}
      >
        {/*TODO params*/}
        <main>{children}</main>
      </Container>
      <Footer />
    </>
  );
}
