import '../styles/globals.css';

import type { AppProps, AppContext } from 'next/app';
import * as React from 'react';
import Head from 'next/head';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { CacheProvider, EmotionCache } from '@emotion/react';
import baseTheme from '../styles/theme';
import { createEmotionCache } from '../lib/createEmotionCache';
import Layout from '../features/layout/Layout';
import { CommonPageProps } from '../services/types';
import { SWRConfig } from 'swr';
import { fetcher } from '../lib/axios';
import { getSelectorsByUserAgent } from 'react-device-detect';
import mediaQuery from 'css-mediaquery';
import App from 'next/app';
import { NextPage } from 'next';
import { BackTopButton } from '@/components/util/BackTopButton';
import DivLayout from '@/features/div/layout/DivLayout';
import { RealEstateInformation } from '@/lib/swagger';
import { googleTagManagerId } from '@/services/utils/gtm';
import { GoogleTagManager, GoogleTagManagerId } from '@/components/util/GoogleTagManager';
import axios from 'axios';
import useSWR from 'swr';
import { SavaStorageProvider } from '@/services/context/SaveStorageProvider';
import Custom504 from './504';

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

// eslint-disable-next-line @typescript-eslint/ban-types
export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
  getLayout?: (page: React.ReactElement) => React.ReactNode;
};

// https://stackoverflow.com/questions/64722812/what-typescript-type-should-nextjs-app-tsx-component-and-pageprops-be
type MyAppProps<P = CommonPageProps> = {
  // 各pageの共通props
  pageProps: P;
  // for mui
  emotionCache?: EmotionCache;
} & Omit<AppProps<P>, 'pageProps'> & { Component: NextPageWithLayout };

// eslint-disable-next-line import/no-default-export
export default function MyApp(props: MyAppProps) {
  // set header infomation
  const infoFetcher = async (url: string) => (await axios.get<RealEstateInformation[]>(url)).data;
  const { data: informations, error: infoError } = useSWR<RealEstateInformation[], Error>(
    '/api/infomation_list',
    infoFetcher,
  );
  const [information, setInformation] = React.useState<RealEstateInformation>({ date: '' });

  React.useEffect(() => {
    if (infoError) {
      setInformation({ title: `infomation api error "${infoError.message}"`, date: '' });
      return;
    }
    if (informations && informations[0]) setInformation(informations[0]);
  }, [informations, infoError]);

  // Use the layout defined at the page level, if available
  const getLayout =
    props.Component.getLayout ??
    (props.pageProps.isBranchSite
      ? (page: React.ReactElement<any, string | React.JSXElementConstructor<any>>) => (
          <DivLayout
            pathName={pageProps.pathName}
            breadcrumbs={pageProps.breadcrumbs}
            headerNavType={pageProps.headerNavType}
            branchItem={pageProps.branchItem}
            information={information}
          >
            {page}
          </DivLayout>
        )
      : (page: React.ReactElement<any, string | React.JSXElementConstructor<any>>) => (
          <Layout
            pathName={pageProps.pathName}
            breadcrumbs={pageProps.breadcrumbs}
            headerNavType={pageProps.headerNavType}
            information={information}
          >
            {page}
          </Layout>
        ));
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;
  const ssrMatchMedia = (query: string) => ({
    matches: mediaQuery.match(query, {
      // The estimated CSS width of the browser.
      width: pageProps.isMobileOnly ? '0px' : '640px',
    }),
  });
  const theme = createTheme(baseTheme, {
    components: {
      // Change the default options of useMediaQuery
      MuiUseMediaQuery: {
        defaultProps: {
          ssrMatchMedia,
        },
      },
    },
  });

  const page = props.pageProps.timeout ? <Custom504 /> : <Component {...pageProps} />;

  return (
    <>
      <GoogleTagManager googleTagManagerId={googleTagManagerId as GoogleTagManagerId} />
      <CacheProvider value={emotionCache}>
        <SWRConfig
          value={{
            fetcher: fetcher,
          }}
        >
          <Head>
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            <title>{pageProps.title}</title>
            {props.pageProps.isBranchSite && <meta name="robots" content="noindex" />}
          </Head>
          <ThemeProvider theme={theme}>
            {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
            <CssBaseline />
            <SavaStorageProvider>
              {getLayout(page)}
              {!props.pageProps.isMobileOnly && <BackTopButton displayPoint={345} />}
            </SavaStorageProvider>
          </ThemeProvider>
        </SWRConfig>
      </CacheProvider>
    </>
  );
}

MyApp.getInitialProps = async (appContext: AppContext) => {
  const userAgent = appContext.ctx.req?.headers['user-agent'] || '';
  const isMobileOnly = getSelectorsByUserAgent(userAgent)?.isMobileOnly;

  // calls page's `getInitialProps` and fills `appProps.pageProps`
  const appProps = await App.getInitialProps(appContext);

  return {
    appProps,
    pageProps: { ...appProps.pageProps, isMobileOnly, pathName: appContext.ctx.asPath },
  };
};
