import { LinkListItem } from '@/components/list/LinkListRow';

export interface PrefectureListItem extends LinkListItem {
  suffix: string;
  code: number;
}

export interface PrefectureAreaDataSet {
  area: string;
  prefectureListItems: PrefectureListItem[];
}

export const hokkaidoArea: PrefectureAreaDataSet = {
  area: '北海道',
  prefectureListItems: [{ title: '北海道', suffix: '', href: '/hokkaido', code: 1 }],
};

export const tohokuArea: PrefectureAreaDataSet = {
  area: '東北',
  prefectureListItems: [
    { title: '青森', suffix: '県', href: '/aomori', code: 2 },
    { title: '岩手', suffix: '県', href: '/iwate', code: 3 },
    { title: '宮城', suffix: '県', href: '/miyagi', code: 4 },
    { title: '秋田', suffix: '県', href: '/akita', code: 5 },
    { title: '山形', suffix: '県', href: '/yamagata', code: 6 },
    { title: '福島', suffix: '県', href: '/fukushima', code: 7 },
  ],
};

export const hokurikuArea: PrefectureAreaDataSet = {
  area: '信越・北陸',
  prefectureListItems: [
    { title: '新潟', suffix: '県', href: '/niigata', code: 15 },
    { title: '富山', suffix: '県', href: '/toyama', code: 16 },
    { title: '石川', suffix: '県', href: '/ishikawa', code: 17 },
    { title: '福井', suffix: '県', href: '/fukui', code: 18 },
    { title: '長野', suffix: '県', href: '/nagano', code: 20 },
  ],
};

export const shutokenArea: PrefectureAreaDataSet = {
  area: '首都圏',
  prefectureListItems: [
    { title: '東京', suffix: '都', href: '/tokyo', code: 13 },
    { title: '神奈川', suffix: '県', href: '/kanagawa', code: 14 },
    { title: '千葉', suffix: '県', href: '/chiba', code: 12 },
    { title: '埼玉', suffix: '県', href: '/saitama', code: 11 },
    { title: '茨城', suffix: '県', href: '/ibaraki', code: 8 },
    { title: '栃木', suffix: '県', href: '/tochigi', code: 9 },
    { title: '群馬', suffix: '県', href: '/gunma', code: 10 },
    { title: '山梨', suffix: '県', href: '/yamanashi', code: 19 },
  ],
};

export const tokaiArea: PrefectureAreaDataSet = {
  area: '東海',
  prefectureListItems: [
    { title: '岐阜', suffix: '県', href: '/gifu', code: 21 },
    { title: '三重', suffix: '県', href: '/mie', code: 24 },
    { title: '愛知', suffix: '県', href: '/aichi', code: 23 },
    { title: '静岡', suffix: '県', href: '/shizuoka', code: 22 },
  ],
};

export const kinkiArea: PrefectureAreaDataSet = {
  area: '近畿',
  prefectureListItems: [
    { title: '滋賀', suffix: '県', href: '/shiga', code: 25 },
    { title: '京都', suffix: '府', href: '/kyoto', code: 26 },
    { title: '大阪', suffix: '府', href: '/osaka', code: 27 },
    { title: '兵庫', suffix: '県', href: '/hyogo', code: 28 },
    { title: '奈良', suffix: '県', href: '/nara', code: 29 },
    { title: '和歌山', suffix: '県', href: '/wakayama', code: 30 },
  ],
};

export const shikokuArea: PrefectureAreaDataSet = {
  area: '中国・四国',
  prefectureListItems: [
    { title: '鳥取', suffix: '県', href: '/tottori', code: 31 },
    { title: '島根', suffix: '県', href: '/shimane', code: 32 },
    { title: '岡山', suffix: '県', href: '/okayama', code: 33 },
    { title: '広島', suffix: '県', href: '/hiroshima', code: 34 },
    { title: '山口', suffix: '県', href: '/yamaguchi', code: 35 },
    { title: '徳島', suffix: '県', href: '/tokushima', code: 36 },
    { title: '香川', suffix: '県', href: '/kagawa', code: 37 },
    { title: '愛媛', suffix: '県', href: '/ehime', code: 38 },
    { title: '高知', suffix: '県', href: '/kochi', code: 39 },
  ],
};

export const kyushuArea: PrefectureAreaDataSet = {
  area: '九州・沖縄',
  prefectureListItems: [
    { title: '福岡', suffix: '県', href: '/fukuoka', code: 40 },
    { title: '佐賀', suffix: '県', href: '/saga', code: 41 },
    { title: '長崎', suffix: '県', href: '/nagasaki', code: 42 },
    { title: '熊本', suffix: '県', href: '/kumamoto', code: 43 },
    { title: '大分', suffix: '県', href: '/oita', code: 44 },
    { title: '宮崎', suffix: '県', href: '/miyazaki', code: 45 },
    { title: '鹿児島', suffix: '県', href: '/kagoshima', code: 46 },
    { title: '沖縄', suffix: '県', href: '/okinawa', code: 47 },
  ],
};

export const footerPrefectureSearchList1: PrefectureAreaDataSet[] = [
  hokkaidoArea,
  tohokuArea,
  hokurikuArea,
  shutokenArea,
];

export const footerPrefectureSearchList2: PrefectureAreaDataSet[] = [
  tokaiArea,
  kinkiArea,
  shikokuArea,
  kyushuArea,
];

export const allPrefectureListItems = [
  ...hokkaidoArea.prefectureListItems,
  ...tohokuArea.prefectureListItems,
  ...hokurikuArea.prefectureListItems,
  ...shutokenArea.prefectureListItems,
  ...tokaiArea.prefectureListItems,
  ...kinkiArea.prefectureListItems,
  ...shikokuArea.prefectureListItems,
  ...kyushuArea.prefectureListItems,
];

export const prefecturePathNameList = footerPrefectureSearchList1
  .concat(footerPrefectureSearchList2)
  .flatMap((list) =>
    list.prefectureListItems.map((prefecture) => prefecture.href.replace('/', '')),
  );

export interface BranchListItem extends LinkListItem {
  prefectureItem?: PrefectureListItem;
  cityCodes: string[];
}

export type BranchItem = BranchListItem | null;

const getPrefectureItem = (prefectureCode: number) => {
  return allPrefectureListItems.filter((item) => item.code === prefectureCode)[0];
};

// prettier-ignore
// cityCodeが空の場合は県全てのエリアを表示
export const branchDataSet: BranchListItem[] = [
  { title: '北海道本部', href: '/div_hokkaido', prefectureItem: getPrefectureItem(1), cityCodes: [] },
  { title: '青森県本部', href: '/div_aomori', prefectureItem: getPrefectureItem(2), cityCodes: [] },
  { title: '岩手県本部', href: '/div_iwate', prefectureItem: getPrefectureItem(3), cityCodes: [] },
  { title: '宮城県本部', href: '/div_miyagi', prefectureItem: getPrefectureItem(4), cityCodes: [] },
  { title: '秋田県本部', href: '/div_akita', prefectureItem: getPrefectureItem(5), cityCodes: [] },
  { title: '山形県本部', href: '/div_yamagata', prefectureItem: getPrefectureItem(6), cityCodes: [] },
  { title: '福島県本部', href: '/div_fukushima', prefectureItem: getPrefectureItem(7), cityCodes: [] },
  { title: '新潟県本部', href: '/div_niigata', prefectureItem: getPrefectureItem(15), cityCodes: [] },
  { title: '富山県本部', href: '/div_toyama', prefectureItem: getPrefectureItem(16), cityCodes: [] },
  { title: '石川県本部', href: '/div_ishikawa', prefectureItem: getPrefectureItem(17), cityCodes: [] },
  { title: '福井県本部', href: '/div_fukui', prefectureItem: getPrefectureItem(18), cityCodes: [] },
  { title: '長野県本部', href: '/div_nagano', prefectureItem: getPrefectureItem(20), cityCodes: [] },
  { title: '東京都本部', href: '/div_tokyo', prefectureItem: getPrefectureItem(13), cityCodes: [] },
  { title: '神奈川県', href: '/div_kanagawa', prefectureItem: getPrefectureItem(14), cityCodes: [] },
  { title: '千葉県本部', href: '/div_chiba', prefectureItem: getPrefectureItem(12), cityCodes: [] },
  { title: '埼玉県本部', href: '/div_saitama', prefectureItem: getPrefectureItem(11), cityCodes: [] },
  { title: '茨城県本部', href: '/div_ibaraki', prefectureItem: getPrefectureItem(8), cityCodes: [] },
  { title: '栃木県本部', href: '/div_tochigi', prefectureItem: getPrefectureItem(9), cityCodes: [] },
  { title: '群馬県本部', href: '/div_gunma', prefectureItem: getPrefectureItem(10), cityCodes: [] },
  { title: '山梨県本部', href: '/div_yamanashi', prefectureItem: getPrefectureItem(19), cityCodes: [] },
  { title: '岐阜県本部', href: '/div_gifu', prefectureItem: getPrefectureItem(21), cityCodes: [] },
  { title: '三重県本部', href: '/div_mie', prefectureItem: getPrefectureItem(24), cityCodes: [] },
  { title: '愛知県本部', href: '/div_aichi', prefectureItem: getPrefectureItem(23), cityCodes: [] },
  { title: '静岡県本部', href: '/div_shizuoka', prefectureItem: getPrefectureItem(22), cityCodes: [] },
  { title: '滋賀県本部', href: '/div_shiga', prefectureItem: getPrefectureItem(25), cityCodes: [] },
  { title: '京都府本部', href: '/div_kyoto', prefectureItem: getPrefectureItem(26), cityCodes: [] },
  { title: '大阪府本部', href: '/div_osaka', prefectureItem: getPrefectureItem(27), cityCodes: [] },
  { title: '兵庫県本部', href: '/div_hyogo', prefectureItem: getPrefectureItem(28), cityCodes: [] },
  { title: '奈良県本部', href: '/div_nara', prefectureItem: getPrefectureItem(29), cityCodes: [] },
  { title: '和歌山県本部', href: '/div_wakayama', prefectureItem: getPrefectureItem(30), cityCodes: [] },
  { title: '鳥取県本部', href: '/div_tottori', prefectureItem: getPrefectureItem(31), cityCodes: [] },
  { title: '島根県', href: '/div_shimane', prefectureItem: getPrefectureItem(32), cityCodes: [] },
  { title: '岡山県本部', href: '/div_okayama', prefectureItem: getPrefectureItem(33), cityCodes: [] },
  { title: '広島県本部', href: '/div_hiroshima', prefectureItem: getPrefectureItem(34), cityCodes: [] },
  { title: '山口県本部', href: '/div_yamaguchi', prefectureItem: getPrefectureItem(35), cityCodes: [] },
  { title: '徳島県本部', href: '/div_tokushima', prefectureItem: getPrefectureItem(36), cityCodes: [] },
  { title: '香川県本部', href: '/div_kagawa', prefectureItem: getPrefectureItem(37), cityCodes: [] },
  { title: '愛媛県本部', href: '/div_ehime', prefectureItem: getPrefectureItem(38), cityCodes: [] },
  { title: '高知県本部', href: '/div_kochi', prefectureItem: getPrefectureItem(39), cityCodes: [] },
  { title: '福岡県本部', href: '/div_fukuoka', prefectureItem: getPrefectureItem(40), cityCodes: [] },
  { title: '佐賀県本部', href: '/div_saga', prefectureItem: getPrefectureItem(41), cityCodes: [] },
  { title: '長崎県本部', href: '/div_nagasaki', prefectureItem: getPrefectureItem(42), cityCodes: [] },
  { title: '熊本県本部', href: '/div_kumamoto', prefectureItem: getPrefectureItem(43), cityCodes: [] },
  { title: '大分県本部', href: '/div_oita', prefectureItem: getPrefectureItem(44), cityCodes: [] },
  { title: '宮崎県本部', href: '/div_miyazaki', prefectureItem: getPrefectureItem(45), cityCodes: [] },
  { title: '鹿児島本部', href: '/div_kagoshima', prefectureItem: getPrefectureItem(46), cityCodes: [] },
  { title: '沖縄本部', href: '/div_okinawa', prefectureItem: getPrefectureItem(47), cityCodes: [] },
  { title: '県央東支部', href: '/div_saitama_kenouhigashi', prefectureItem: getPrefectureItem(11), cityCodes: ['11201', '11207', '11211', '11212', '11218', ' 11235', '11239', '11241', '11245', '11341', '11342', '11343', '11346', '11347', '11348', '11349', '11361', '11362', '11363', '11365', '11369', '11381', '11383', '11385', '11408'] },
  { title: '県西支部', href: '/div_saitama_kensei', prefectureItem: getPrefectureItem(11), cityCodes: ['11208', '11209', '11215', '11225', '11227', '11228', '11229', '11230', '11242', '11245', '11324', '11326', '11327'] },
  { title: '県北支部', href: '/div_saitama_kenhoku', prefectureItem: getPrefectureItem(11), cityCodes: ['11101', '11102', '11103', '11104', '11105', '11202', '11206', '11210', '11216', '11217', '11219', '11231', '11233', '11301'] },
  { title: '県中央支部', href: '/div_saitama_kenchuo', prefectureItem: getPrefectureItem(11), cityCodes: ['11106', '11107', '11108', '11109', '11110', '11203', '11223', '11224'] },
  { title: '県東支部', href: '/div_saitama_kento', prefectureItem: getPrefectureItem(11), cityCodes: ['11214', '11221', '11222', '11232', '11234', '11237', '11238', '11240', '11243', '11246', '11442', '11464', '11465'] },
  { title: '市川浦安支部', href: '/div_chiba_ichikawaurayasu', prefectureItem: getPrefectureItem(12), cityCodes: ['12203', '12227'] },
  { title: '千葉支部', href: '/div_chiba_chiba', prefectureItem: getPrefectureItem(12), cityCodes: ['12101', '12102', '12103', '12104', '12105', '12106'] },
  { title: '京葉支部', href: '/div_chiba_keiyo', prefectureItem: getPrefectureItem(12), cityCodes: ['12204', '12216', '12224', '12231', '12232'] },
  { title: '東葛支部', href: '/div_chiba_tokatsu', prefectureItem: getPrefectureItem(12), cityCodes: ['12207', '12208', '12217', '12220', '12222'] },
  { title: '北総支部', href: 'div_chiba_hokuso', prefectureItem: getPrefectureItem(12), cityCodes: ['12202', '12211', '12212', '12215', '12221', '12228', '12230', '12233', '12235', '12236', '12322', '12329', '12342', '12347', '12349'] },
  { title: '内房支部', href: '/div_chiba_uchibo', prefectureItem: getPrefectureItem(12), cityCodes: ['12205', '12206', '12219', '12223', '12225', '12226', '12229', '12234', '12463'] },
  { title: '外房支部', href: '/div_chiba_sotobo', prefectureItem: getPrefectureItem(12), cityCodes: ['12210', '12213', '12218', '12237', '12238', '12239', '12403', '12409', '12410', '12421', '12422', '12423', '12424', '12426', '12427', '12441', '12443'] },
  { title: '千代田支部', href: '/div_tokyo_chiyoda', prefectureItem: getPrefectureItem(13), cityCodes: ['13101'] },
  { title: '中央支部', href: '/div_tokyo_chuo', prefectureItem: getPrefectureItem(13), cityCodes: ['13102', '13361', '13362', '13363', '13364', '13381', '13382', '13401', '13402', '13421'] },
  { title: '城東第一支部', href: '/div_tokyo_joto1', prefectureItem: getPrefectureItem(13), cityCodes: ['13121', '13122'] },
  { title: '江戸川支部', href: '/div_tokyo_edogawa', prefectureItem: getPrefectureItem(13), cityCodes: ['13123'] },
  { title: '城東第二支部', href: '/div_tokyo_joto2', prefectureItem: getPrefectureItem(13), cityCodes: ['13106', '13107', '13108'] },
  { title: '港支部', href: '/div_tokyo_minato', prefectureItem: getPrefectureItem(13), cityCodes: ['13103'] },
  { title: '世田谷支部', href: '/div_tokyo_setagaya', prefectureItem: getPrefectureItem(13), cityCodes: ['13112'] },
  { title: '城南支部', href: '/div_tokyo_jonan', prefectureItem: getPrefectureItem(13), cityCodes: ['13109', '13110', '13111'] },
  { title: '新宿支部', href: '/div_tokyo_shinjuku', prefectureItem: getPrefectureItem(13), cityCodes: ['13104'] },
  { title: '渋谷支部', href: '/div_tokyo_shibuya', prefectureItem: getPrefectureItem(13), cityCodes: ['13113'] },
  { title: '中野・杉並支部', href: '/div_tokyo_nakanosuginami', prefectureItem: getPrefectureItem(13), cityCodes: ['13114', '13115'] },
  { title: '豊島・文京支部', href: '/div_tokyo_toshimabunkyo', prefectureItem: getPrefectureItem(13), cityCodes: ['13105', '13116'] },
  { title: '城北支部', href: '/div_tokyo_johoku', prefectureItem: getPrefectureItem(13), cityCodes: ['13117', '13118', '13119'] },
  { title: '練馬支部', href: '/div_tokyo_nerima', prefectureItem: getPrefectureItem(13), cityCodes: ['13120'] },
  { title: '多摩北支部', href: '/div_tokyo_tamakita', prefectureItem: getPrefectureItem(13), cityCodes: ['13202', '13211', '13213', '13220', '13221', '13222', '13223', '13229'] },
  { title: '多摩中央支部', href: '/div_tokyo_tamachuo', prefectureItem: getPrefectureItem(13), cityCodes: ['13206', '13210', '13214', '13215'] },
  { title: '多摩東支部', href: '/div_tokyo_tamahigashi', prefectureItem: getPrefectureItem(13), cityCodes: ['13203', '13204', '13208', '13219'] },
  { title: '多摩西支部', href: '/div_tokyo_tamanishi', prefectureItem: getPrefectureItem(13), cityCodes: ['13205', '13207', '13218', '13227', '13228', '13303', '13305', '13307', '13308'] },
  { title: '多摩南支部', href: '/div_tokyo_tamaminami', prefectureItem: getPrefectureItem(13), cityCodes: ['13201', '13212', '13224', '13225'] },
  { title: '町田支部', href: '/div_tokyo_machida', prefectureItem: getPrefectureItem(13), cityCodes: ['13209'] },
  { title: '横浜市', href: '/div_kanagawa_yokohama', prefectureItem: getPrefectureItem(14), cityCodes: ['14101', '14102', '14103', '14104', '14105', '14106', '14107', '14108', '14109', '14110', '14111', '14112', '14113', '14114', '14115', '14116', '14117', '14118'] },
  { title: '川崎市', href: '/div_kanagawa_kawasaki', prefectureItem: getPrefectureItem(14), cityCodes: ['14131', '14132', '14133', '14134', '14135', '14136', '14137'] },
  { title: '相模原市', href: '/div_kanagawa_sagami', prefectureItem: getPrefectureItem(14), cityCodes: ['14151', '14152', '14153', '14212', '14213', '14214', '14215', '14216', '14218', '14401', '14402'] },
  { title: '湘南支部', href: '/div_kanagawa_shonan', prefectureItem: getPrefectureItem(14), cityCodes: ['14203', '14204', '14205', '14206', '14207', '14211', '14217', '14321', '14341', '14342', '14361', '14362', '14363', '14364', '14366', '14382', '14383', '14384'] },
  { title: '横須賀市', href: '/div_kanagawa_yokosuka', prefectureItem: getPrefectureItem(14), cityCodes: ['14201', '14208', '14210', '14301'] },
  { title: '小田原市', href: '/div_kanagawa_odawara', prefectureItem: getPrefectureItem(14), cityCodes: ['14206'] },
  { title: '名東支部', href: '/div_aichi_meito', prefectureItem: getPrefectureItem(23), cityCodes: [] },
  { title: '名西支部', href: '/div_aichi_meisei', prefectureItem: getPrefectureItem(23), cityCodes: [] },
  { title: '名南支部', href: '/div_aichi_meinan', prefectureItem: getPrefectureItem(23), cityCodes: [] },
  { title: '名北支部', href: '/div_aichi_meihoku', prefectureItem: getPrefectureItem(23), cityCodes: [] },
  { title: '中央支部', href: '/div_aichi_chuo', prefectureItem: getPrefectureItem(23), cityCodes: [] },
  { title: '尾張支部', href: '/div_aichi_owari', prefectureItem: getPrefectureItem(23), cityCodes: [] },
  { title: '三河支部', href: '/div_aichi_mikawa', prefectureItem: getPrefectureItem(23), cityCodes: [] },
  { title: '東支部', href: '/div_kyoto_higashi', prefectureItem: getPrefectureItem(26), cityCodes: ['26103', '26105', '26106', '26107', '26110'] },
  { title: '西支部', href: '/div_kyoto_nishi', prefectureItem: getPrefectureItem(26), cityCodes: ['26108', '26111', '26208', '26209', '26303'] },
  { title: '南支部', href: '/div_kyoto_minami', prefectureItem: getPrefectureItem(26), cityCodes: ['26109', '26204', '26207', '26210', '26211', '26214', '26322', '26343', '26344', '26364', '26365', '26366', '26367'] },
  { title: '北支部', href: '/div_kyoto_kita', prefectureItem: getPrefectureItem(26), cityCodes: ['26101', '26102', '26104'] },
  { title: '三丹支部', href: '/div_kyoto_santan', prefectureItem: getPrefectureItem(26), cityCodes: ['26201', '26202', '26203', '26205', '26206', '26212', '26213', '26407', '26463', '26465'] },
  { title: '北大阪支部', href: '/div_osaka_kitaosaka', prefectureItem: getPrefectureItem(27), cityCodes: ['27203', '27204', '27205', '27207', '27211', '27220', '27224', '27301', '27321', '27322'] },
  { title: '北支部', href: '/div_osaka_kita', prefectureItem: getPrefectureItem(27), cityCodes: ['27102', '27103', '27104', '27113', '27114', '27117', '27118', '27123', '27124', '27127'] },
  { title: '大阪東支部', href: '/div_osaka_osakahigashi', prefectureItem: getPrefectureItem(27), cityCodes: ['27209', '27210', '27212', '27215', '27218', '27223', '27227', '27229', '27230'] },
  { title: '中央支部', href: '/div_osaka_chuo', prefectureItem: getPrefectureItem(27), cityCodes: ['27106', '27107', '27108', '27128'] },
  { title: 'なにわ南支部', href: '/div_osaka_naniwaminami', prefectureItem: getPrefectureItem(27), cityCodes: ['27109', '27111', '27115', '27116', '27119', '27120', '27121', '27122', '27125', '27126'] },
  { title: '大阪南支部', href: '/div_osaka_osakaminami', prefectureItem: getPrefectureItem(27), cityCodes: ['27141', '27142', '27143', '27144', '27145', '27146', '27147', '27202', '27206', '27208', '27213', '27214', '27216', '27217', '27219', '27221', '27222', '27225', '27226', '27228', '27231', '27232', '27341', '27361', '27362', '27366', '27381', '27382', '27383'] },
  { title: '姫路支部', href: '/div_hyogo_himeji', prefectureItem: getPrefectureItem(28), cityCodes: ['28201', '28208', '28209', '28210', '28212', '28213', '28215', '28216', '28218', '28220', '28222', '28225', '28227', '28228', '28229', '28365', '28381', '28382', '28442', '28443', '28446', '28464', '28481', '28501', '28585', '28586'] },
  { title: '神戸支部', href: '/div_hyogo_kobe', prefectureItem: getPrefectureItem(28), cityCodes: ['28101', '28102', '28105', '28106', '28107', '28108', '28109', '28110', '28111', '28203', '28205', '28224', '28226'] },
  { title: '阪神支部', href: '/div_hyogo_hanshin', prefectureItem: getPrefectureItem(28), cityCodes: ['28202', '28204', '28206', '28207', '28214', '28217', '28219', '28221', '28223', '28301'] },
  { title: '平塚市', href: '/div_kanagawa_hiratsuka', prefectureItem: getPrefectureItem(14), cityCodes: ['14203'] },
  { title: '鎌倉市', href: '/div_kanagawa_kamakura', prefectureItem: getPrefectureItem(14), cityCodes: ['14204'] },
  { title: '藤沢市', href: '/div_kanagawa_hujisawa', prefectureItem: getPrefectureItem(14), cityCodes: ['14205'] },
  { title: '茅ヶ崎市', href: '/div_kanagawa_chigasaki', prefectureItem: getPrefectureItem(14), cityCodes: ['14207'] },
  { title: '秦野市', href: '/div_kanagawa_hadano', prefectureItem: getPrefectureItem(14), cityCodes: ['14211'] },
  { title: '南足柄市', href: '/div_kanagawa_minamiashigara', prefectureItem: getPrefectureItem(14), cityCodes: ['14217'] },
  { title: '寒川町', href: '/div_kanagawa_samukawa', prefectureItem: getPrefectureItem(14), cityCodes: ['14321'] },
  { title: '大磯町', href: '/div_kanagawa_oiso', prefectureItem: getPrefectureItem(14), cityCodes: ['14341'] },
  { title: '二宮町', href: '/div_kanagawa_ninomiya', prefectureItem: getPrefectureItem(14), cityCodes: ['14342'] },
  { title: '中井町', href: '/div_kanagawa_ashigara_nakai', prefectureItem: getPrefectureItem(14), cityCodes: ['14361'] },
  { title: '大井町', href: '/div_kanagawa_ashigara_ooi', prefectureItem: getPrefectureItem(14), cityCodes: ['14362'] },
  { title: '松田町', href: '/div_kanagawa_ashigara_matsuda', prefectureItem: getPrefectureItem(14), cityCodes: ['14363'] },
  { title: '山北町', href: '/div_kanagawa_ashigara_yamakita', prefectureItem: getPrefectureItem(14), cityCodes: ['14364'] },
  { title: '開成町', href: '/div_kanagawa_ashigara_kaisei', prefectureItem: getPrefectureItem(14), cityCodes: ['14366'] },
  { title: '箱根町', href: '/div_kanagawa_ashigara_hakone', prefectureItem: getPrefectureItem(14), cityCodes: ['14382'] },
  { title: '真鶴町', href: '/div_kanagawa_ashigara_manazuru', prefectureItem: getPrefectureItem(14), cityCodes: ['14383'] },
  { title: '湯河原町', href: '/div_kanagawa_ashigara_yugawara', prefectureItem: getPrefectureItem(14), cityCodes: ['14384'] },
  { title: '横須賀支部', href: '/div_kanagawa_yokosuga', prefectureItem: getPrefectureItem(14), cityCodes: ['14201'] },
  { title: '逗子市', href: '/div_kanagawa_zushi', prefectureItem: getPrefectureItem(14), cityCodes: ['14208'] },
  { title: '三浦市', href: '/div_kanagawa_miura', prefectureItem: getPrefectureItem(14), cityCodes: ['14210'] },
  { title: '葉山町', href: '/div_kanagawa_miura_hayama', prefectureItem: getPrefectureItem(14), cityCodes: ['14301'] },
  { title: '厚木市', href: '/div_kanagawa_atsugi', prefectureItem: getPrefectureItem(14), cityCodes: ['14212'] },
  { title: '大和市', href: '/div_kanagawa_yamato', prefectureItem: getPrefectureItem(14), cityCodes: ['14213'] },
  { title: '伊勢原市', href: '/div_kanagawa_isehara', prefectureItem: getPrefectureItem(14), cityCodes: ['14214'] },
  { title: '海老名市', href: '/div_kanagawa_ebina', prefectureItem: getPrefectureItem(14), cityCodes: ['14215'] },
  { title: '座間市', href: '/div_kanagawa_zama', prefectureItem: getPrefectureItem(14), cityCodes: ['14216'] },
  { title: '綾瀬市', href: '/div_kanagawa_ayase', prefectureItem: getPrefectureItem(14), cityCodes: ['14218'] },
  { title: '愛川町', href: '/div_kanagawa_aikawa', prefectureItem: getPrefectureItem(14), cityCodes: ['14401'] },
  { title: '清川村', href: '/div_kanagawa_kiyokawa', prefectureItem: getPrefectureItem(14), cityCodes: ['14402'] },
];

export const branchPathNameList = branchDataSet.flatMap((branch) => branch.href);
