import * as React from 'react';
import Image, { StaticImageData } from 'next/image';
import { Box, Button, SxProps, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { NextLink } from '../link/NextLink';

export interface NavItem {
  title: string;
  href: string;
  imageData: StaticImageData;
  titleStyle?: SxProps;
  bgColor?: string;
  hoverBgColor?: string;
  height?: string;
}

const SearchButtonTitle = styled(Typography)(() => ({
  color: '#ffffff',
  fontWeight: 900,
  lineHeight: 1.25,
  textAlign: 'center',
}));

const SearchIconWrap = styled(Box)(() => ({
  position: 'relative',
  bgcolor: 'primary.light',
  width: '75px',
  minWidth: '75px',
  height: '75px',
  minHeight: '75px',
}));

export const NavLinkButton: React.VFC<NavItem> = ({
  title,
  href,
  imageData,
  titleStyle,
  bgColor,
  hoverBgColor,
  height = '148px',
}) => (
  <Button
    component={NextLink}
    href={href}
    sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      width: '100%',
      height: height,
      rowGap: '8px',
      bgcolor: bgColor,
      '&:hover ': {
        backgroundColor: hoverBgColor ?? 'rgba(0, 189, 62, 0.8)',
      },
    }}
  >
    <SearchIconWrap>
      <Image src={imageData} alt="" width="75" height="75" />
    </SearchIconWrap>
    <SearchButtonTitle sx={titleStyle}>{title}</SearchButtonTitle>
  </Button>
);
