import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';

interface MessageDialog {
  children: React.ReactNode;
  buttonText: string;
  isOpen: boolean;
  handleClose: () => void;
}

export const MessageDialog = ({ children, buttonText, isOpen, handleClose }: MessageDialog) => (
  <Dialog open={isOpen} onClose={handleClose}>
    <DialogContent>{children}</DialogContent>
    <DialogActions>
      <Button onClick={handleClose} autoFocus>
        {buttonText}
      </Button>
    </DialogActions>
  </Dialog>
);
