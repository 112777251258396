import * as React from 'react';
import Image from 'next/image';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import NotesIcon from '@mui/icons-material/Notes';
import { Badge, Box, Button, Divider, Link, Stack, Typography, useMediaQuery } from '@mui/material';
import { useTheme, styled } from '@mui/material/styles';
import StarIcon from '@mui/icons-material/Star';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import logo from '@/public/images/div/logo.png';
import logoIcon from '@/public/logo-icon.png';
import logoIconSP from '@/public/logo-icon_sp.png';
import { breadcrumbItem, RabbyBreadcrumbs } from '@/components/link/RabbyBreadcrumbs';
import { BranchItem } from '@/services/data/prefectureLinkList';
import { Menu } from '@/features/layout/Menu';
import { HeaderNavList } from '@/features/layout/HeaderNavList';
import { RealEstateInformation } from '@/lib/swagger';
import { NextLink } from '@/components/link/NextLink';
import { useSaveSearchCondToLocalStorage } from '@/services/hooks/useSaveSearchCondToLocalStorage';

const Item = styled(Button)(({ theme }) => ({
  padding: theme.spacing(1),
  textAlign: 'center',
  width: 70,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  rowGap: 6,
}));

const ItemForSP = styled(Button)(({ theme }) => ({
  textAlign: 'center',
  width: '33.33%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: theme.palette.primary.main,
  borderRadius: 0,
}));

const Count = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  textAlign: 'center',
  fontWeight: 'bold',
  color: theme.palette.accent.main,
  position: 'relative',
}));

const StyledBadge = styled(Badge)(() => ({
  '& .MuiBadge-badge': {
    right: -4,
    top: 12,
    fontWeight: 'bold',
    fontSize: '10.5px',
    padding: '0 4px',
    minWidth: 25,
    height: 25,
    borderRadius: '50%',
    lineHeight: '25px',
  },
}));

const StackTypography = styled(Typography)(() => ({
  textAlign: 'center',
  lineHeight: 1.2,
}));

const LogoSection: React.VFC<{ branchItem?: BranchItem }> = ({ branchItem }) => {
  return (
    <Button
      component={Link}
      href={branchItem ? branchItem.href : ''}
      display="flex"
      alignItems="flex-end"
      sx={{ p: [0, 1] }}
    >
      <Box
        sx={{
          position: 'relative',
          width: [114.5, 186],
          height: [39.5, 64],
          my: [0, 1],
        }}
      >
        <Image src={logo} alt="ラビーネット不動産" />
        <Box
          component="span"
          sx={{
            position: 'absolute',
            display: 'inline-block',
            width: ['76px', '122px'],
            height: ['18px', '26px'],
            lineHeight: ['18px', '26px'],
            right: 0,
            bottom: '-4px',
            textAlign: 'center',
            color: '#ffffff',
            fontWeight: 'bold',
            fontSize: ['10px', '15px'],
          }}
        >
          {branchItem?.title}
        </Box>
      </Box>
      <Box
        sx={{
          position: 'relative',
          width: 47.5,
          height: 70,
          mb: 0.5,
          display: { xs: 'none', sm: 'block' },
        }}
      >
        <Image src={logoIcon} alt="ラビーネット不動産" width="47.5" height="70" />
      </Box>
      <Box
        sx={{
          position: 'relative',
          width: 43,
          height: 50,
          ml: 0.5,
          mb: -0.5,
          display: { xs: 'block', sm: 'none' },
        }}
      >
        <Image src={logoIconSP} alt="ラビーネット不動産" width="43" height="50" />
      </Box>
    </Button>
  );
};

export function Header({
  breadcrumbs,
  headerNavType,
  branchItem,
  infomation,
  isTopPage,
}: {
  breadcrumbs?: breadcrumbItem[];
  headerNavType?: 'buy' | 'rent' | 'resort' | 'agency';
  branchItem?: BranchItem;
  infomation?: RealEstateInformation;
  isTopPage?: boolean;
}) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const { count: savedCondCount } = useSaveSearchCondToLocalStorage();

  const [open, setOpen] = React.useState(false);

  const handleMenuClose = () => {
    setOpen(false);
  };

  const mypageCondHref = `${branchItem ? branchItem.href : ''}/mypage/cond`;

  return (
    <>
      <AppBar position="relative" color="inherit" sx={{ minWidth: { xs: 'none', sm: '1000px' } }}>
        <Toolbar
          disableGutters={true}
          sx={{
            pl: { xs: 1.25, sm: 0 },
            justifyContent: 'space-between',
            width: '100%',
            maxWidth: 'md',
            minWidth: { xs: 'none', sm: '1000px' },
            m: '0 auto',
            borderBottom: { xs: '1px solid', sm: 'none' },
            borderColor: { xs: 'grey.300' },
          }}
        >
          {isTopPage ? (
            <Typography component="h1">
              <LogoSection branchItem={branchItem} />
            </Typography>
          ) : (
            <LogoSection branchItem={branchItem} />
          )}
          {!matches && (
            <IconButton
              size="large"
              edge="start"
              aria-label="menu"
              onClick={() => setOpen(!open)}
              sx={{
                flexDirection: 'column',
                fontSize: '12px',
                color: 'grey.600',
                mt: -1.125,
              }}
            >
              <NotesIcon fontSize="large" />
              <Typography variant="caption" sx={{ lineHeight: 0 }}>
                メニュー
              </Typography>
            </IconButton>
          )}
          {matches && (
            <Stack
              direction="row"
              divider={<Divider orientation="vertical" flexItem />}
              spacing={0.5}
              sx={{
                margin: '4px 0',
                px: '4px',
                borderRight: '1px solid rgba(0, 0, 0, 0.12)',
                borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
              }}
            >
              <Item>
                <Count>
                  <StarIcon fontSize="large" color="disabled" />
                  <Typography
                    variant="caption"
                    sx={{
                      fontSize: '12px',
                      fontWeight: 'bold',
                      right: '-12px',
                      bottom: '-4px',
                      position: 'absolute',
                    }}
                  >
                    0件
                  </Typography>
                </Count>
                <StackTypography variant="caption" color="text.primary">
                  とりあえず
                  <br />
                  保存リスト
                </StackTypography>
              </Item>
              <Item>
                <Count>
                  <AccessTimeIcon fontSize="large" color="disabled" />
                  <Typography
                    variant="caption"
                    sx={{
                      fontSize: '12px',
                      fontWeight: 'bold',
                      right: '-12px',
                      bottom: '-4px',
                      position: 'absolute',
                    }}
                  >
                    0件
                  </Typography>
                </Count>
                <StackTypography variant="caption" color="text.primary">
                  最近見た
                  <br />
                  物件
                </StackTypography>
              </Item>
              <Item onClick={() => (window.location.href = mypageCondHref)}>
                <Count>
                  <ManageSearchIcon fontSize="large" color="disabled" />
                  <Typography
                    variant="caption"
                    sx={{
                      fontSize: '12px',
                      fontWeight: 'bold',
                      right: '-12px',
                      bottom: '-4px',
                      position: 'absolute',
                    }}
                  >
                    {savedCondCount}件
                  </Typography>
                </Count>
                <StackTypography variant="caption" color="text.primary">
                  登録した
                  <br />
                  検索条件
                </StackTypography>
              </Item>
            </Stack>
          )}
        </Toolbar>
        {!matches && <Menu open={open} handleMenuClose={handleMenuClose} branchItem={branchItem} />}

        <Box
          display="flex"
          justifyContent="center"
          margin={[0.5, 1.25]}
          sx={{ display: { xs: 'flex', sm: 'none' } }}
        >
          <NextLink
            href={infomation?.url || '#'}
            sx={{
              fontSize: 'small',
              fontWeight: '700',
              WebkitTextDecoration: 'underline',
              textDecoration: 'underline',
              textDecorationColor: 'rgba(1, 87, 155, 0.4)',
              color: '#01579b',
            }}
          >
            {infomation?.title || ''}
          </NextLink>
        </Box>

        {!matches && (
          <Toolbar sx={{ p: 0 }}>
            <Stack
              direction="row"
              divider={<Divider orientation="vertical" flexItem sx={{ borderColor: 'white' }} />}
              sx={{ width: '100%', bgcolor: 'primary.light' }}
            >
              <ItemForSP>
                <StyledBadge badgeContent={0} showZero color="accent">
                  <StarIcon fontSize="large" sx={{ color: 'white' }} />
                </StyledBadge>
                <StackTypography variant="caption" color="white" fontWeight="600">
                  とりあえず保存リスト
                </StackTypography>
              </ItemForSP>
              <ItemForSP>
                <StyledBadge badgeContent={0} showZero color="accent">
                  <AccessTimeIcon fontSize="large" sx={{ color: 'white' }} />
                </StyledBadge>
                <StackTypography variant="caption" color="white" fontWeight="600">
                  最近見た物件
                </StackTypography>
              </ItemForSP>
              <ItemForSP onClick={() => (window.location.href = mypageCondHref)}>
                <StyledBadge badgeContent={savedCondCount} showZero color="accent">
                  <ManageSearchIcon fontSize="large" sx={{ color: 'white' }} />
                </StyledBadge>
                <StackTypography variant="caption" color="white" fontWeight="600">
                  登録した検索条件
                </StackTypography>
              </ItemForSP>
            </Stack>
          </Toolbar>
        )}
      </AppBar>

      {infomation && (
        <Box
          display="flex"
          justifyContent="center"
          margin={1.5}
          sx={{ display: { xs: 'none', sm: 'flex' }, minWidth: { xs: 'none', sm: '1000px' } }}
        >
          <NextLink
            href={infomation.url || '#'}
            target="_blank"
            sx={{
              fontSize: 'small',
              fontWeight: '700',
              WebkitTextDecoration: 'underline',
              textDecoration: 'underline',
              textDecorationColor: 'rgba(1, 87, 155, 0.4)',
              color: '#01579b',
            }}
          >
            {infomation.title || ''}
          </NextLink>
        </Box>
      )}
      {matches && headerNavType && headerNavType != 'agency' && (
        <HeaderNavList navType={headerNavType} branchItem={branchItem} />
      )}
      <RabbyBreadcrumbs breadcrumbs={breadcrumbs} />
    </>
  );
}
