import { useLocalStorage } from 'react-use';
import add from 'date-fns/add';
import { Duration, isBefore } from 'date-fns';
import { useEffect, useState } from 'react';
import { SearchResortProperty } from '@/services/schemas/SearchResortPropertySchema';
import { SearchRentProperty } from '@/services/schemas/SearchRentPropertySchema';
import { SearchSaleProperty } from '@/services/schemas/SearchSalePropertySchema';
import { City, Railway, Station, Zipcode } from '@/lib/swagger';
import { ResortAreaData } from '../data/resortAreaList';
import { PrefectureListItem } from '../data/prefectureLinkList';

export interface AreaOrRailway {
  prefecture?: string;
  cities?: string[];
  towns?: string[];
  railways?: { railway?: string; stations?: string[] }[];
  resortArea?: string;
}

export interface StorageValue {
  timestamp: number;
  type: string;
  category: string;
  areaOrRailway: AreaOrRailway;
  cond: SearchRentProperty & SearchSaleProperty & SearchResortProperty;
}

interface AreaOrLineProps {
  prefectureItem?: PrefectureListItem;
  cities?: City[];
  towns?: Zipcode[];
  lines?: Railway[];
  stations?: Station[];
  resortAreaItem?: ResortAreaData;
}

interface addItemProps extends AreaOrLineProps {
  type: string;
  category: string;
  cond: SearchRentProperty & SearchSaleProperty & SearchResortProperty;
}

const expireDuration: Duration = { days: 90 };
const limitNum = 20;
const key = 'search-cond';

const getExpireDate = (date: Date) => add(date, expireDuration);

export const useSaveSearchCondToLocalStorage = () => {
  const initialValue: StorageValue[] = [];
  const [value, setValue, remove] = useLocalStorage(key, initialValue);
  const [storageValue, setStorageValue] = useState<StorageValue[]>();

  if (typeof window === 'object') {
    window.addEventListener(
      'storage',
      function (e) {
        if (e.storageArea?.getItem(key)) {
          const parsedStorageValue = JSON.parse(e.storageArea?.getItem(key) || '');
          if (Array.isArray(parsedStorageValue)) {
            setStorageValue(JSON.parse(e.storageArea?.getItem(key) || ''));
          }
        }
      },
      false,
    );
  }

  const _setValue = (items: StorageValue[]) => {
    setValue(items);
    setStorageValue(items);
  };

  useEffect(() => {
    if (value?.length) {
      const filterValue = value.filter((item) =>
        isBefore(new Date(), getExpireDate(new Date(item.timestamp))),
      );
      if (!filterValue.length) {
        remove();
      }
      _setValue(filterValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _convertAreaOrRailway = ({
    prefectureItem,
    cities,
    towns,
    lines,
    stations,
    resortAreaItem,
  }: AreaOrLineProps) => {
    return {
      prefecture: `${prefectureItem?.title}${prefectureItem?.suffix}`,
      cities: cities?.map((city) => city.name),
      towns: towns?.map((town) => town.name),
      railways: lines?.map((line) => {
        return {
          railway: line.name,
          stations: stations
            ?.filter((station) => station.code.indexOf(line.code) === 0)
            .map((station) => station.name),
        };
      }),
      resortArea: resortAreaItem?.title,
    };
  };

  const addItem = ({
    type,
    category,
    cond,
    prefectureItem,
    cities,
    towns,
    lines,
    stations,
    resortAreaItem,
  }: addItemProps) => {
    const areaOrRailway = _convertAreaOrRailway({
      prefectureItem,
      cities,
      towns,
      lines,
      stations,
      resortAreaItem,
    });
    const newItem = { timestamp: Date.now(), type, category, areaOrRailway, cond };
    if (storageValue && storageValue.length >= limitNum) {
      return;
    }
    if (storageValue) {
      setValue([...storageValue, newItem]);
      return;
    }
    setValue([newItem]);
  };

  const removeItem = (timestamp: number) => {
    if (!storageValue) {
      return;
    }
    const filterValue = storageValue.filter((item) => item.timestamp !== timestamp);
    if (!filterValue.length) {
      remove();
      return;
    }
    setValue([...filterValue]);
  };

  return {
    value: storageValue,
    count: storageValue?.length || 0,
    addItem,
    removeItem,
  };
};
