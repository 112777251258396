import { useTheme } from '@mui/material';
import { useMediaQuery } from '@mui/material';

export const useViewSize = () => {
  // mui default xs 0px, sm, 600px, md 900px, lg 1200px, xl 1536px
  const theme = useTheme();
  const isMobileSize = useMediaQuery(theme.breakpoints.down('sm'));

  return { isMobileSize };
};
