import { Box, Breadcrumbs, Typography, useMediaQuery, useTheme } from '@mui/material';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { NextLink } from './NextLink';

export interface breadcrumbItem {
  title: string;
  href: string;
}

interface Props {
  breadcrumbs?: breadcrumbItem[];
}

const SPBreadcrumbsStyle = {
  height: '30px',
  overflowX: 'auto',
  '& .MuiBreadcrumbs-ol': {
    flexWrap: 'nowrap',
  },
  '& .MuiBreadcrumbs-li': {
    bgcolor: '#ffffff',
    position: 'relative',
    display: 'table-cell',
    zIndex: 12,
    '&:before': {
      position: 'absolute',
      top: 0,
      right: '-20px',
      display: 'block',
      content: '""',
      borderStyle: 'solid',
      borderColor: 'transparent transparent transparent #ccc',
      borderWidth: '15px 10px 15px 10px',
      zIndex: 10,
      bgcolor: '#ffffff',
    },
    '&:nth-of-type(1)': {
      a: {
        p: '5px 5px 5px 15px',
      },
    },
    '&:nth-last-of-type(3)': {
      '&:before': {
        bgcolor: 'transparent',
      },
    },
    '&:nth-last-of-type(1)': {
      bgcolor: 'transparent',
      mr: '5px',
      '&:before': {
        border: 'none',
      },
    },
  },
};

const SPLinkStyle = {
  display: 'table-cell',
  fontSize: '10px',
  lineHeight: '20px',
  textDecoration: 'none',
  color: 'text.primary',
  whiteSpace: 'nowrap',
  width: 'max-content',
  p: '5px 5px 5px 0px',
  '&:after': {
    position: 'absolute',
    top: 0,
    right: '-19px',
    display: 'block',
    content: '""',
    borderStyle: 'solid',
    borderColor: 'transparent transparent transparent #fff',
    borderWidth: '15px 10px 15px 10px',
    zIndex: 11,
  },
};

const SPTextStyle = {
  display: 'table-cell',
  fontSize: '10px',
  lineHeight: '20px',
  whiteSpace: 'nowrap',
  p: '5px 5px 5px 0px',
};

export const RabbyBreadcrumbs: React.VFC<Props> = ({ breadcrumbs }) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  if (!breadcrumbs) {
    return <></>;
  }

  return (
    <Box
      sx={{
        borderTop: 'solid 2px',
        borderBottom: { xs: 'none', sm: 'solid 2px' },
        borderColor: { xs: 'grey.300', sm: 'grey.300' },
        py: { xs: 0, sm: 0.75 },
        mb: { xs: 0, sm: '10px' },
        boxShadow: { xs: 'none', sm: '0px 0px 0px 2px #f2f2f2 inset' },
        minWidth: { xs: 'none', sm: '1000px' },
        bgcolor: { xs: 'grey.200', sm: 'transparent' },
        position: 'relative',
      }}
    >
      <Breadcrumbs
        separator={matches ? <NavigateNextIcon fontSize="small" /> : ''}
        aria-label="breadcrumb"
        sx={
          matches
            ? {
                maxWidth: '1000px',
                margin: '0 auto',
              }
            : SPBreadcrumbsStyle
        }
      >
        {breadcrumbs.map((item, index) => {
          return !item.href.length ? (
            <Typography
              key={index + 1}
              color="text.primary"
              variant="caption"
              sx={matches ? {} : SPTextStyle}
            >
              {item.title}
            </Typography>
          ) : (
            <NextLink
              key={index + 1}
              href={item.href}
              sx={
                matches
                  ? { color: 'primary.main', fontSize: '12px', textDecoration: 'underline' }
                  : SPLinkStyle
              }
            >
              {!matches && item.title.match(/TOP/) ? 'TOP' : item.title}
            </NextLink>
          );
        })}
      </Breadcrumbs>
    </Box>
  );
};
