import * as React from 'react';
import Image from 'next/image';
import { Box, Button, Grid, Typography, TextField, Link, List, ListItem } from '@mui/material';
import { styled } from '@mui/material/styles';
import navtitleRent from '../../public/navtitle-rent.png';
import navtitleSale from '../../public/navtitle-sale.png';
import resortIcon from '../../public/resort-icon.png';
import searchIcon01 from '../../public/search-icon01.png';
import searchIcon02 from '../../public/search-icon02.png';
import labbySelf from '../../public/labby-self.png';
import labbyBody from '../../public/labby-body.png';
import labbyLink from '../../public/labby-link.png';
import { NavItem, NavLinkButton } from '../../components/button/NavLinkButton';
import { buyCategoryDataSet, rentCategoryDataSet } from '../../services/data/propertyCategoryList';
import { PrefectureListItem } from '@/services/data/prefectureLinkList';
import { useRouter } from 'next/router';
import { useRef } from 'react';
import { propertyClassificationById } from '@/services/utils/propertyClassification';
import { MessageDialog } from '@/components/dialog/MessageDialog';

const SearchIconWrap = styled(Box)(() => ({
  position: 'relative',
  bgcolor: 'primary.light',
  width: '75px',
  minWidth: '75px',
  height: '75px',
  minHeight: '75px',
}));

const NavTitleH2 = styled(Typography)(() => ({
  fontWeight: 'bold',
  color: '#ffffff',
  textAlign: 'center',
  fontSize: '36px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'flex-end',
  columnGap: '8px',
  marginBottom: '16px',
}));

const NavTitleH3 = styled(Typography)(() => ({
  fontWeight: 'bold',
  color: '#ffffff',
  textAlign: 'center',
}));

const SearchTextField = styled(TextField)(() => ({
  width: '100%',
  '& .MuiOutlinedInput-root': {
    backgroundColor: 'white',
    '& input': {
      padding: '4px 6px 5px',
      fontSize: '12px',
      color: '#000000',
    },
  },
}));

interface Props {
  prefectureItem?: PrefectureListItem;
}

export const HomeNav: React.VFC<Props> = ({ prefectureItem }) => {
  const agencyNavItems: NavItem[] = [
    {
      title: '地域から探す',
      href: prefectureItem ? `/agency${prefectureItem.href}/area` : '/agency/area',
      imageData: searchIcon01,
    },
    {
      title: '沿線/駅から探す',
      href: prefectureItem ? `/agency${prefectureItem.href}/line` : '/agency/line',
      imageData: searchIcon02,
    },
  ];

  const agencySearchText = useRef('');
  const propertySearchNumber = useRef('');
  const [dialogMessage, setDialogMessage] = React.useState('');
  const [messageDialogOpen, setMessageDialog] = React.useState(false);

  const router = useRouter();

  const routePushByPropertyId = async (value: string) => {
    const validation = /^[0-9]+$/.test(value);
    if (!validation) {
      setDialogMessage('半角数字で入力して下さい。');
      setMessageDialog(true);
      return;
    }

    const propertyClass = await propertyClassificationById(value);
    if (!propertyClass) {
      setDialogMessage('物件が見つかりませんでした。');
      setMessageDialog(true);
      return;
    }

    if ('timeout' in propertyClass) {
      setDialogMessage(
        '現在アクセスが集中しており検索に失敗しました。しばらく時間をおいてから再度お試し下さい。',
      );
      setMessageDialog(true);
      return;
    }

    router.push({ pathname: `/${propertyClass?.path}/${value}` });
  };

  const AgencySearchMethod = (inputText: string) => {
    if (!inputText) {
      router.push({ pathname: '/agency' });
      return;
    }
    router.push({ pathname: '/agency/syogo', query: { syogo: inputText } });
  };

  return (
    <Box
      sx={{
        backgroundImage: prefectureItem ? 'none' : `url('/top-nav-background.gif')`,
        backgroundRepeat: 'repeat',
        minWidth: { xs: 'none', sm: '1000px' },
        pr: { xs: 0, sm: 0, headerBP: prefectureItem ? 0 : 24 },
        display: { xs: 'none', sm: 'block' },
      }}
    >
      <Box
        sx={{
          maxWidth: prefectureItem ? '100%' : '800px',
          width: '100%',
          m: '0 auto',
          px: prefectureItem ? 0 : 1,
          backgroundColor: 'white',
        }}
      >
        <Grid
          container
          columnSpacing={prefectureItem ? 2.5 : 1}
          position="relative"
          minWidth="630px"
        >
          <Grid item xs={4} minWidth="210px">
            <Box p={2} bgcolor="primary.light" height="100%">
              <NavTitleH2 variant="h2">
                <Image src={navtitleRent} alt="" width="48px" height="44.8px" />
                借りる
              </NavTitleH2>
              <List sx={{ display: 'flex', flexWrap: 'wrap', position: 'relative' }}>
                {rentCategoryDataSet.map((item, index) => (
                  <ListItem key={`rent-nav-${index}`} sx={{ width: '50%', padding: 0 }}>
                    <NavLinkButton
                      title={item.title}
                      href={prefectureItem ? `${item.href}${prefectureItem.href}` : item.href}
                      imageData={item.pcImage}
                    />
                  </ListItem>
                ))}
              </List>
            </Box>
          </Grid>

          <Grid item xs={4} minWidth="210px">
            <Box p={2} bgcolor="primary.light" height="100%">
              <NavTitleH2 variant="h2">
                <Image src={navtitleSale} alt="" width="48px" height="44.8px" />
                買う
              </NavTitleH2>
              <List sx={{ display: 'flex', flexWrap: 'wrap', position: 'relative' }}>
                {buyCategoryDataSet.map((item, index) => (
                  <ListItem key={`sale-nav-${index}`} sx={{ width: '50%', padding: 0 }}>
                    <NavLinkButton
                      title={item.title}
                      href={prefectureItem ? `${item.href}${prefectureItem.href}` : item.href}
                      imageData={item.pcImage}
                    />
                  </ListItem>
                ))}
              </List>
            </Box>
          </Grid>

          <Grid
            item
            container
            xs={4}
            rowSpacing={prefectureItem ? 2.5 : 1}
            minWidth="210px"
            alignContent={prefectureItem ? 'flex-start' : 'none'}
          >
            {!prefectureItem && (
              <Grid item xs={12} justifyContent="center">
                <Button
                  component={Link}
                  href="/buy/resort"
                  sx={{
                    bgcolor: 'primary.light',
                    p: 2,
                    gap: 1,
                    display: 'flex',
                    alignItems: 'center',
                    height: '100%',
                    borderRadius: 0,
                    '&:hover ': {
                      backgroundColor: 'rgba(0, 180, 62, 0.98)',
                    },
                  }}
                >
                  <SearchIconWrap>
                    <Image src={resortIcon} alt="" />
                  </SearchIconWrap>
                  <Typography variant="h3" fontWeight="bold" color="white" width="148px">
                    リゾート物件を探す
                  </Typography>
                </Button>
              </Grid>
            )}
            <Grid item xs={12} justifyContent="center">
              <Box bgcolor="primary.light" p={2} height={prefectureItem ? 'none' : '100%'}>
                <NavTitleH3 variant="h3" pb={1.5}>
                  物件番号から探す
                </NavTitleH3>
                <Box display="flex" justifyContent="center" gap={1}>
                  <SearchTextField
                    variant="outlined"
                    placeholder="物件番号を入力"
                    onChange={(e) => {
                      propertySearchNumber.current = e.target.value;
                    }}
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') routePushByPropertyId(propertySearchNumber.current);
                    }}
                  />
                  <Button
                    size="small"
                    variant="contained"
                    color="accent"
                    sx={{
                      fontWeight: 'bold',
                      lineHeight: 1.2,
                      padding: '4px 8px',
                      minWidth: '48px',
                    }}
                    onClick={() => {
                      routePushByPropertyId(propertySearchNumber.current);
                    }}
                  >
                    検索
                  </Button>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} justifyContent="center">
              <Box bgcolor="primary.light" p={2} height={prefectureItem ? 'none' : '100%'}>
                <NavTitleH3 variant="h3" pb={1}>
                  不動産会社を探す
                </NavTitleH3>
                <List
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    position: 'relative',
                  }}
                >
                  {agencyNavItems.map((item, index) => (
                    <ListItem key={`sale-nav-${index}`} sx={{ width: '50%', padding: 0 }}>
                      <NavLinkButton {...item} />
                    </ListItem>
                  ))}
                </List>
                {!prefectureItem && (
                  <Box>
                    <Typography
                      color="white"
                      variant="h4"
                      fontSize="1.05rem"
                      fontWeight="900"
                      textAlign="center"
                      mb={1.5}
                    >
                      お店の名前（商号）から探す
                    </Typography>
                    <Box display="flex" justifyContent="center" gap={1}>
                      <SearchTextField
                        variant="outlined"
                        onChange={(e) => {
                          agencySearchText.current = e.target.value;
                        }}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') AgencySearchMethod(agencySearchText.current);
                        }}
                        placeholder="不動産会社を入力"
                      />
                      <Button
                        size="small"
                        variant="contained"
                        color="accent"
                        sx={{
                          fontWeight: 'bold',
                          lineHeight: 1.2,
                          padding: '4px 8px',
                          minWidth: '48px',
                        }}
                        onClick={() => {
                          AgencySearchMethod(agencySearchText.current);
                        }}
                      >
                        検索
                      </Button>
                    </Box>
                  </Box>
                )}
              </Box>
            </Grid>
          </Grid>

          {!prefectureItem && (
            <Box
              sx={{
                position: 'absolute',
                width: '207px',
                top: '30px',
                right: '-205px',
                display: { xs: 'none', sm: 'none', headerBP: 'block' },
              }}
            >
              <Box
                sx={{
                  position: 'relative',
                  width: '142px',
                  height: '112px',
                  ml: '20px',
                }}
              >
                <Image src={labbySelf} alt="ラビーネット不動産" />
              </Box>
              <Box sx={{ position: 'relative', width: '207px', height: '289px' }}>
                <Image src={labbyBody} alt="ラビーネット不動産" priority />
              </Box>
              <Box
                sx={{
                  position: 'relative',
                  width: '107px',
                  height: '63px',
                  ml: '25px',
                  mt: '10px',
                }}
              >
                <Link
                  href="https://www.zennichi.or.jp/about/rabby/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Image src={labbyLink} alt="ラビーネット不動産" />
                </Link>
              </Box>
            </Box>
          )}
        </Grid>
      </Box>

      <MessageDialog
        isOpen={messageDialogOpen}
        buttonText={'OK'}
        handleClose={() => {
          setMessageDialog(false);
        }}
      >
        <Box sx={{ width: '400px' }}>{dialogMessage}</Box>
      </MessageDialog>
    </Box>
  );
};
