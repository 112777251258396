import spRentIcon01 from '@/public/sp-rent-icon01.png';
import spRentIcon02 from '@/public/sp-rent-icon02.png';
import spRentIcon03 from '@/public/sp-rent-icon03.png';
import spRentIcon04 from '@/public/sp-rent-icon04.png';
import spRentIcon05 from '@/public/sp-rent-icon05.png';
import spRentIcon06 from '@/public/sp-rent-icon06.png';
import spSaleIcon01 from '@/public/sp-sale-icon01.png';
import spSaleIcon02 from '@/public/sp-sale-icon02.png';
import spSaleIcon03 from '@/public/sp-sale-icon03.png';
import spSaleIcon04 from '@/public/sp-sale-icon04.png';
import spSaleIcon05 from '@/public/sp-sale-icon05.png';
import spSaleIcon06 from '@/public/sp-sale-icon06.png';

import rentIcon01 from '@/public/rent-icon01.png';
import rentIcon02 from '@/public/rent-icon02.png';
import rentIcon03 from '@/public/rent-icon03.png';
import rentIcon04 from '@/public/rent-icon04.png';
import rentIcon05 from '@/public/rent-icon05.png';
import rentIcon06 from '@/public/rent-icon06.png';
import saleIcon01 from '@/public/sale-icon01.png';
import saleIcon02 from '@/public/sale-icon02.png';
import saleIcon03 from '@/public/sale-icon03.png';
import saleIcon04 from '@/public/sale-icon04.png';
import saleIcon05 from '@/public/sale-icon05.png';
import saleIcon06 from '@/public/sale-icon06.png';

import titleIconRentHome from '@/public/titleicon-rent-home.png';
import titleIconRentShop from '@/public/titleicon-rent-shop.png';
import titleIconRentParking from '@/public/titleicon-rent-parking.png';
import titleIconRentOffice from '@/public/titleicon-rent-office.png';
import titleIconRentLand from '@/public/titleicon-rent-land.png';
import titleIconRentOther from '@/public/titleicon-rent-other.png';
import titleIconBuyMansion from '@/public/titleicon-buy-mansion.png';
import titleIconBuyHouse from '@/public/titleicon-buy-house.png';
import titleIconBuyShop from '@/public/titleicon-buy-shop.png';
import titleIconBuyOffice from '@/public/titleicon-buy-office.png';
import titleIconBuyLand from '@/public/titleicon-buy-land.png';
import titleIconBuyOther from '@/public/titleicon-buy-other.png';
import titleIconMember from '@/public/titleicon-member.png';
import titleIconResort from '@/public/titleicon-resort.png';
import { StaticImageData } from 'next/image';
import {
  BuyCategoryType,
  BuySpecialType,
  RentCategoryType,
  RentSpecialType,
  ResortCategoryType,
} from '@/services/types';

import fav001 from '@/public/fav-001.png';
import fav002 from '@/public/fav-002.png';
import fav003 from '@/public/fav-003.png';
import fav004 from '@/public/fav-004.png';
import fav005 from '@/public/fav-005.png';
import fav006 from '@/public/fav-006.png';
import fav007 from '@/public/fav-007.png';
import fav008 from '@/public/fav-008.png';
import fav009 from '@/public/fav-009.png';
import fav010 from '@/public/fav-010.png';

import spFav001 from '@/public/sp-fav-001.png';
import spFav002 from '@/public/sp-fav-002.png';
import spFav003 from '@/public/sp-fav-003.png';
import spFav004 from '@/public/sp-fav-004.png';
import spFav005 from '@/public/sp-fav-005.png';
import spFav006 from '@/public/sp-fav-006.png';
import spFav007 from '@/public/sp-fav-007.png';
import spFav008 from '@/public/sp-fav-008.png';
import spFav009 from '@/public/sp-fav-009.png';
import spFav010 from '@/public/sp-fav-010.png';

export interface BaseCategoryItem {
  type?: 'rent' | 'buy' | 'resort';
  category: RentCategoryType | BuyCategoryType | ResortCategoryType | 'agency' | 'resortAll';
  title: string;
  href: string;
  inquiryQuery?: string;
}
export interface CategoryItem extends BaseCategoryItem {
  spImage: StaticImageData;
  pcImage: StaticImageData;
  pcTitleImage: StaticImageData;
  description: string;
  metaDescription: string;
  metaTitle: string;
}

export const getCategoryItem = ({ type, category }: { type: string; category: string }) => {
  const categoryItemList =
    type === 'rent'
      ? rentCategoryDataSet
      : type === 'buy'
      ? buyCategoryDataSet
      : type === 'resort'
      ? resortCategoryDataSet
      : [];

  return categoryItemList.find((categoryItem) => categoryItem.category === category);
};

export const rentCategoryDataSet: CategoryItem[] = [
  {
    type: 'rent',
    category: 'home',
    title: '賃貸マンション・アパート・一戸建て',
    href: '/rent/home',
    inquiryQuery: 'c=rent&t=home&sb=',
    spImage: spRentIcon01,
    pcImage: rentIcon01,
    pcTitleImage: titleIconRentHome,
    description:
      '賃貸住宅を借りるなら、物件検索サイト「ラビーネット不動産」で。賃貸マンション・賃貸アパート・賃貸一戸建ての物件情報を住みたいエリア/沿線・駅から検索し、賃料/間取り/駅からの徒歩といった希望条件で絞り込むことができます。このほか、物件の探し方から契約、引越、入居時の手続まで、お部屋探しに役立つ情報が満載。このサイトは全国組織の業界団体である公益社団法人全日本不動産協会が運営する不動産情報サイトのため、安心して物件をお探しいただけます。住まい探しのことならラビーネット不動産にお任せください！',
    metaDescription:
      '賃貸住宅を探すなら【ラビーネット不動産】日本全国の賃貸マンション・アパート・一戸建て物件情報を検索することができます。こだわり条件検索にもしっかり対応。ラビーネット不動産なら、あなたにぴったりの賃貸住宅がきっと見つかります。',
    metaTitle: '賃貸マンション・アパート・一戸建ての物件検索なら【ラビーネット不動産】',
  },
  {
    type: 'rent',
    category: 'parking',
    title: '貸駐車場',
    href: '/rent/parking',
    inquiryQuery: 'c=rent&t=parking&sb=',
    spImage: spRentIcon02,
    pcImage: rentIcon02,
    pcTitleImage: titleIconRentParking,
    description:
      '駐車場を検索するなら、物件検索サイト「ラビーネット不動産」で。月極駐車場・賃貸駐車場の物件情報を探したいエリア/沿線・駅から検索し、賃料などの希望条件で絞り込むことができます。このサイトは全国組織の業界団体である公益社団法人全日本不動産協会が運営する不動産情報サイトのため、安心して物件をお探しいただけます。賃駐車場のことならラビーネット不動産にお任せください！',
    metaDescription:
      '賃貸駐車場を探すなら【ラビーネット不動産】日本全国の月極駐車場などの賃貸駐車場情報を検索することができます。こだわり条件検索にもしっかり対応。ラビーネット不動産なら、あなたにぴったりの賃貸駐車場がきっと見つかります。',
    metaTitle: '月極駐車場・賃貸駐車場の物件検索なら【ラビーネット不動産】',
  },
  {
    type: 'rent',
    category: 'shop',
    title: '貸店舗',
    href: '/rent/shop',
    inquiryQuery: 'c=rent&t=shop&sb=',
    spImage: spRentIcon03,
    pcImage: rentIcon03,
    pcTitleImage: titleIconRentShop,
    description:
      '店舗を検索するなら、物件検索サイト「ラビーネット不動産」で。賃貸店舗・貸店舗の物件情報を探したいエリア/沿線・駅から検索し、賃料/面積/駅からの徒歩といった希望条件で絞り込むことができます。このサイトは全国組織の業界団体である公益社団法人全日本不動産協会が運営する不動産情報サイトのため、安心して物件をお探しいただけます。賃店舗のことならラビーネット不動産にお任せください！',
    metaDescription:
      '賃貸店舗・貸店舗を探すなら【ラビーネット不動産】日本全国の貸店舗情報を検索することができます。こだわり条件検索にもしっかり対応。ラビーネット不動産なら、あなたにぴったりの賃貸店舗・貸店舗がきっと見つかります。',
    metaTitle: '賃貸店舗・貸店舗の物件検索なら【ラビーネット不動産】',
  },
  {
    type: 'rent',
    category: 'office',
    title: '貸事務所',
    href: '/rent/office',
    inquiryQuery: 'c=rent&t=office&sb=',
    spImage: spRentIcon04,
    pcImage: rentIcon04,
    pcTitleImage: titleIconRentOffice,
    description:
      '事務所・オフィスを検索するなら、物件検索サイト「ラビーネット不動産」で。賃貸事務所・貸事務所の物件情報を探したいエリア/沿線・駅から検索し、賃料/面積/駅からの徒歩といった希望条件で絞り込むことができます。このサイトは全国組織の業界団体である公益社団法人全日本不動産協会が運営する不動産情報サイトのため、安心して物件をお探しいただけます。賃事務所のことならラビーネット不動産にお任せください！',
    metaDescription:
      '賃貸事務所・貸事務所を探すなら【ラビーネット不動産】日本全国の貸事務所情報を検索することができます。こだわり条件検索にもしっかり対応。ラビーネット不動産なら、あなたにぴったりの貸事務所がきっと見つかります。',
    metaTitle: '賃貸事務所・貸事務所の物件検索なら【ラビーネット不動産】',
  },
  {
    type: 'rent',
    category: 'land',
    title: '貸土地',
    href: '/rent/land',
    inquiryQuery: 'c=rent&t=land&sb=',
    spImage: spRentIcon05,
    pcImage: rentIcon05,
    pcTitleImage: titleIconRentLand,
    description:
      '土地を検索するなら、物件検索サイト「ラビーネット不動産」で。貸土地・貸地の物件情報を探したいエリア/沿線・駅から検索し、賃料/面積/駅からの徒歩といった希望条件で絞り込むことができます。このサイトは全国組織の業界団体である公益社団法人全日本不動産協会が運営する不動産情報サイトのため、安心して物件をお探しいただけます。賃貸土地・貸し地のことならラビーネット不動産にお任せください！',
    metaDescription:
      '賃貸土地・貸し土地を探すなら【ラビーネット不動産】日本全国の貸し土地情報を検索することができます。こだわり条件検索にもしっかり対応。ラビーネット不動産なら、あなたにぴったりの貸し土地がきっと見つかります。',
    metaTitle: '賃貸土地・貸し土地の物件検索なら【ラビーネット不動産】',
  },
  {
    type: 'rent',
    category: 'other',
    title: '貸ビル・倉庫・その他',
    href: '/rent/other',
    inquiryQuery: 'c=rent&t=other&sb=',
    spImage: spRentIcon06,
    pcImage: rentIcon06,
    pcTitleImage: titleIconRentOther,
    description:
      'ビル・倉庫・工場など事業用物件を検索するなら、物件検索サイト「ラビーネット不動産」で。貸ビル・倉庫・その他の物件情報を探したいエリア/沿線・駅から検索し、賃料/面積/築年数といった希望条件で絞り込むことができます。このサイトは全国組織の業界団体である公益社団法人全日本不動産協会が運営する不動産情報サイトのため、安心して物件をお探しいただけます。ビル・倉庫・工場といった賃貸事業用物件のことならラビーネット不動産にお任せください！',
    metaDescription:
      '賃貸ビル・倉庫・工場などを探すなら【ラビーネット不動産】日本全国の物件情報を検索することができます。こだわり条件検索にもしっかり対応。ラビーネット不動産なら、あなたにぴったりの物件がきっと見つかります。',
    metaTitle: '賃貸ビル・倉庫・工場などの物件検索なら【ラビーネット不動産】',
  },
];

export const rentCategoryList = ['home', 'parking', 'shop', 'office', 'land', 'other'];

export const buyCategoryDataSet: CategoryItem[] = [
  {
    type: 'buy',
    category: 'mansion',
    title: '売マンション',
    href: '/buy/mansion',
    inquiryQuery: 'c=buy&t=mansion&sb=',
    spImage: spSaleIcon01,
    pcImage: saleIcon01,
    pcTitleImage: titleIconBuyMansion,
    description:
      'マンションの購入をご検討中なら、物件検索サイト「ラビーネット不動産」で。売マンションの物件情報を住みたいエリア/沿線・駅から検索し、価格/築年数/間取り/駅からの徒歩といった希望条件で絞り込むことができます。このほか、物件の探し方から契約、引越、入居時の手続まで、お部屋探しに役立つ情報が満載。このサイトは全国組織の業界団体である公益社団法人全日本不動産協会が運営する不動産情報サイトのため、安心して物件をお探しいただけます。新築マンション・中古マンションのことならラビーネット不動産にお任せください！',
    metaDescription:
      '中古マンション・新築マンションを探すなら【ラビーネット不動産】日本全国のマンション情報を検索することができます。こだわり条件検索にもしっかり対応。ラビーネット不動産なら、あなたにぴったりのマンションがきっと見つかります。',
    metaTitle: '中古マンション・新築マンションの物件検索なら【ラビーネット不動産】',
  },
  {
    type: 'buy',
    category: 'house',
    title: '売一戸建て',
    href: '/buy/house',
    inquiryQuery: 'c=buy&t=house&sb=',
    spImage: spSaleIcon02,
    pcImage: saleIcon02,
    pcTitleImage: titleIconBuyHouse,
    description:
      '一戸建ての購入をご検討中なら、物件検索サイト「ラビーネット不動産」で。売一戸建ての物件情報を住みたいエリア/沿線・駅から検索し、価格/築年数/間取り/駅からの徒歩といった希望条件で絞り込むことができます。このほか、物件の探し方から契約、引越、入居時の手続まで、お部屋探しに役立つ情報が満載。このサイトは全国組織の業界団体である公益社団法人全日本不動産協会が運営する不動産情報サイトのため、安心して物件をお探しいただけます。新築一戸建て・中古一戸建て・一軒家のことならラビーネット不動産にお任せください！',
    metaDescription:
      '中古一戸建て・新築一戸建てを探すなら【ラビーネット不動産】日本全国の一戸建て情報を検索することができます。こだわり条件検索にもしっかり対応。ラビーネット不動産なら、あなたにぴったりの一戸建てがきっと見つかります。',
    metaTitle: '中古一戸建て・新築一戸建ての物件検索なら【ラビーネット不動産】',
  },
  {
    type: 'buy',
    category: 'shop',
    title: '売店舗',
    href: '/buy/shop',
    inquiryQuery: 'c=buy&t=shop&sb=',
    spImage: spSaleIcon03,
    pcImage: saleIcon03,
    pcTitleImage: titleIconBuyShop,
    description:
      '店舗の購入をご検討中なら、物件検索サイト「ラビーネット不動産」で。売り店舗の物件情報を探したいエリア/沿線・駅から検索し、価格/面積/駅からの徒歩といった希望条件で絞り込むことができます。このサイトは全国組織の業界団体である公益社団法人全日本不動産協会が運営する不動産情報サイトのため、安心して物件をお探しいただけます。売店舗のことならラビーネット不動産にお任せください！',
    metaDescription:
      '売り店舗を探すなら【ラビーネット不動産】日本全国の売り店舗情報を検索することができます。こだわり条件検索にもしっかり対応。ラビーネット不動産なら、あなたにぴったりの売り店舗がきっと見つかります。',
    metaTitle: '売り店舗の物件検索なら【ラビーネット不動産】',
  },
  {
    type: 'buy',
    category: 'office',
    title: '売事務所',
    href: '/buy/office',
    inquiryQuery: 'c=buy&t=office&sb=',
    spImage: spSaleIcon04,
    pcImage: saleIcon04,
    pcTitleImage: titleIconBuyOffice,
    description:
      '事務所・オフィスの購入をご検討中なら、物件検索サイト「ラビーネット不動産」で。売り事務所の物件情報を探したいエリア/沿線・駅から検索し、価格/面積/駅からの徒歩といった希望条件で絞り込むことができます。このサイトは全国組織の業界団体である公益社団法人全日本不動産協会が運営する不動産情報サイトのため、安心して物件をお探しいただけます。売事務所のことならラビーネット不動産にお任せください！',
    metaDescription:
      '売り事務所を探すなら【ラビーネット不動産】日本全国の売り事務所情報を検索することができます。こだわり条件検索にもしっかり対応。ラビーネット不動産なら、あなたにぴったりの売り事務所がきっと見つかります。',
    metaTitle: '売り事務所の物件検索なら【ラビーネット不動産】',
  },
  {
    type: 'buy',
    category: 'land',
    title: '売土地',
    href: '/buy/land',
    inquiryQuery: 'c=buy&t=land&sb=',
    spImage: spSaleIcon05,
    pcImage: saleIcon05,
    pcTitleImage: titleIconBuyLand,
    description:
      '土地の購入をご検討中なら、物件検索サイト「ラビーネット不動産」で。売土地・売り地の物件情報を探したいエリア/沿線・駅から検索し、価格/面積/駅からの徒歩といった希望条件で絞り込むことができます。このサイトは全国組織の業界団体である公益社団法人全日本不動産協会が運営する不動産情報サイトのため、安心して物件をお探しいただけます。売地のことならラビーネット不動産にお任せください！',
    metaDescription:
      '土地・売り地を探すなら【ラビーネット不動産】日本全国の売り地情報を検索することができます。こだわり条件検索にもしっかり対応。ラビーネット不動産なら、あなたにぴったりの売り地がきっと見つかります。',
    metaTitle: '土地・売り地の物件検索なら【ラビーネット不動産】',
  },
  {
    type: 'buy',
    category: 'other',
    title: '売ビル・一括マンション・その他',
    href: '/buy/other',
    inquiryQuery: 'c=buy&t=other&sb=',
    spImage: spSaleIcon06,
    pcTitleImage: titleIconBuyOther,
    pcImage: saleIcon06,
    description:
      'ビル・倉庫・工場など事業用物件の購入をご検討中なら、物件検索サイト「ラビーネット不動産」で。売ビル・倉庫・その他の物件情報を探したいエリア/沿線・駅から検索し、価格/面積/駅からの徒歩といった希望条件で絞り込むことができます。このサイトは全国組織の業界団体である公益社団法人全日本不動産協会が運営する不動産情報サイトのため、安心して物件をお探しいただけます。ビル・倉庫・工場といった売買事業用物件のことならラビーネット不動産にお任せください！',
    metaDescription:
      '売りビル・一棟売マンションなどを探すなら【ラビーネット不動産】日本全国の物件情報を検索することができます。こだわり条件検索にもしっかり対応。ラビーネット不動産なら、あなたにぴったりの物件がきっと見つかります。',
    metaTitle: '売りビル・一棟売マンションなどの物件検索なら【ラビーネット不動産】',
  },
];

// TODO: pcImage, spImage正しいものに
export const agencyCategoryItem: CategoryItem = {
  title: '不動産会社',
  href: '/agency',
  category: 'agency',
  spImage: titleIconMember,
  pcImage: titleIconMember,
  pcTitleImage: titleIconMember,
  description:
    '不動産を検索するなら、物件検索サイト「ラビーネット不動産」で。居住用物件（住宅）・事業用物件の物件情報をお探しのエリア/沿線・駅から検索し、希望条件で物件を絞り込むことができます。このほか、物件の探し方から契約、引越、入居時の手続まで、お部屋探しに役立つ情報が満載。このサイトは全国組織の業界団体である公益社団法人全日本不動産協会が運営する不動産情報サイトのため、安心して物件をお探しいただけます。賃貸物件・売買物件のことならラビーネット不動産にお任せください！',
  metaDescription:
    '不動産会社を探すなら【ラビーネット不動産】日本全国の不動産会社を検索することができます。',
  metaTitle: '不動産会社を探す｜不動産を探すなら【ラビーネット不動産】',
};

export const resortCategoryItem: CategoryItem = {
  title: 'リゾート物件',
  href: '/buy/resort',
  type: 'resort',
  category: 'resortAll',
  spImage: titleIconResort,
  pcImage: titleIconResort,
  pcTitleImage: titleIconResort,
  description:
    'リゾート物件の購入をご検討中なら、物件検索サイト「ラビーネット不動産」で。リゾートマンション/別荘・保養所/別荘用地の物件情報を探したいエリア/沿線・駅から検索し、価格帯で絞り込むことができます。このサイトは全国組織の業界団体である公益社団法人全日本不動産協会が運営する不動産情報サイトのため、安心して物件をお探しいただけます。リゾート物件のことならラビーネット不動産にお任せください！',
  metaDescription:
    'リゾート物件を探すなら【ラビーネット不動産】日本全国のリゾート物件情報を検索することができます。こだわり条件検索にもしっかり対応。ラビーネット不動産なら、あなたにぴったりのリゾート物件がきっと見つかります。',
  metaTitle: 'リゾート物件の物件検索なら【ラビーネット不動産】',
};

export const buyCategoryList = ['mansion', 'house', 'shop', 'office', 'land', 'other'];

export const resortCategoryDataSet: CategoryItem[] = [
  {
    type: 'resort',
    category: 'mansion',
    title: 'リゾートマンション',
    href: '/buy/resort/mansion',
    inquiryQuery: 'c=buy&t=resort&sb=mansion',
    spImage: titleIconResort,
    pcImage: titleIconResort,
    pcTitleImage: titleIconResort,
    description:
      'リゾート物件の購入をご検討中なら、物件検索サイト「ラビーネット不動産」で。リゾートマンション/別荘・保養所/別荘用地の物件情報を探したいエリア/沿線・駅から検索し、価格帯で絞り込むことができます。このサイトは全国組織の業界団体である公益社団法人全日本不動産協会が運営する不動産情報サイトのため、安心して物件をお探しいただけます。リゾート物件のことならラビーネット不動産にお任せください！',
    metaDescription:
      'リゾートマンションを探すなら【ラビーネット不動産】日本全国のリゾート物件情報を検索することができます。こだわり条件検索にもしっかり対応。ラビーネット不動産なら、あなたにぴったりのリゾートマンションがきっと見つかります。',
    metaTitle: 'リゾートマンションの物件検索なら【ラビーネット不動産】',
  },
  {
    type: 'resort',
    category: 'villa',
    title: '別荘・保養所',
    href: '/buy/resort/villa',
    inquiryQuery: 'c=buy&t=resort&sb=villa',
    spImage: titleIconResort,
    pcImage: titleIconResort,
    pcTitleImage: titleIconResort,
    description:
      'リゾート物件の購入をご検討中なら、物件検索サイト「ラビーネット不動産」で。リゾートマンション/別荘・保養所/別荘用地の物件情報を探したいエリア/沿線・駅から検索し、価格帯で絞り込むことができます。このサイトは全国組織の業界団体である公益社団法人全日本不動産協会が運営する不動産情報サイトのため、安心して物件をお探しいただけます。リゾート物件のことならラビーネット不動産にお任せください！',
    metaDescription:
      '別荘・保養所を探すなら【ラビーネット不動産】日本全国のリゾート物件情報を検索することができます。こだわり条件検索にもしっかり対応。ラビーネット不動産なら、あなたにぴったりの別荘・保養所がきっと見つかります。',
    metaTitle: '別荘・保養所の物件検索なら【ラビーネット不動産】',
  },
  {
    type: 'resort',
    category: 'land',
    title: '別荘地・土地',
    href: '/buy/resort/land',
    inquiryQuery: 'c=buy&t=resort&sb=land',
    spImage: titleIconResort,
    pcImage: titleIconResort,
    pcTitleImage: titleIconResort,
    description:
      'リゾート物件の購入をご検討中なら、物件検索サイト「ラビーネット不動産」で。リゾートマンション/別荘・保養所/別荘用地の物件情報を探したいエリア/沿線・駅から検索し、価格帯で絞り込むことができます。このサイトは全国組織の業界団体である公益社団法人全日本不動産協会が運営する不動産情報サイトのため、安心して物件をお探しいただけます。リゾート物件のことならラビーネット不動産にお任せください！',
    metaDescription:
      '別荘地・土地を探すなら【ラビーネット不動産】日本全国のリゾート物件情報を検索することができます。こだわり条件検索にもしっかり対応。ラビーネット不動産なら、あなたにぴったりの別荘地・土地がきっと見つかります。',
    metaTitle: '別荘地・土地の物件検索なら【ラビーネット不動産】',
  },
];

export const resortCategoryList = ['mansion', 'villa', 'land'];

interface RecommendLinkList {
  title: string;
  href: string;
  description: string;
  imageData: StaticImageData;
  spImageData: StaticImageData;
}

export const rentRecommendLinkList: RecommendLinkList[] = [
  {
    title: '新築賃貸',
    href: '/rent/home/special/shinchiku',
    description:
      'お部屋・設備が真新しい、そんな新築の賃貸物件で新しい生活をスタートさせたい方はこちらの特集がおすすめ！',
    imageData: fav001,
    spImageData: spFav001,
  },
  {
    title: 'ペット相談可',
    href: '/rent/home/special/petok',
    description: '大切なペットと一緒に暮らしたい。ペット相談可の賃貸物件をお探しの方はこちら。',
    imageData: fav002,
    spImageData: spFav002,
  },
  {
    title: '一人暮らし向け',
    href: '/rent/home/special/single',
    description:
      '新たに一人暮らしをスタートさせる方、住み替えをお考えの方におすすめな、一人暮らし向け賃貸物件の特集はこちら。',
    imageData: fav003,
    spImageData: spFav003,
  },
  {
    title: '高齢者相談可',
    href: '/rent/home/special/kourei',
    description: 'シニア世代におすすめの、高齢者の入居相談が可能な賃貸物件をお探しの方はこちら。',
    imageData: fav004,
    spImageData: spFav004,
  },
  {
    title: '駅チカ',
    href: '/rent/home/special/ekichika',
    description:
      '通勤・通学・お出かけにも便利な、駅から近い部屋に住みたい！そんな方におすすめの駅近賃貸物件特集はこちら。',
    imageData: fav005,
    spImageData: spFav005,
  },
];

export const saleRecommendLinkList: RecommendLinkList[] = [
  {
    title: '新築[マンション]',
    href: '/buy/mansion/special/shinchiku',
    description:
      'お部屋・設備が真新しい、そんな新築物件の購入をお考えの方にはこちらの特集がおすすめ！',
    imageData: fav006,
    spImageData: spFav006,
  },
  {
    title: 'ペット相談可 [マンション]',
    href: '/buy/mansion/special/petok',
    description: '大切なペットと一緒に暮らしたい。ペット相談可の物件をお探しの方はこちら。',
    imageData: fav007,
    spImageData: spFav007,
  },
  {
    title: '空き家バンク登録物件 [一戸建て]',
    href: '/buy/house/special/akiya',
    description: '「空き家バンク」に登録されている物件の購入をお考えの方はこちら。',
    imageData: fav008,
    spImageData: spFav008,
  },
  {
    title: '駐車場2台分 [一戸建て]',
    href: '/buy/house/special/car2',
    description: '駐車場に車を2台停められる物件を購入したい…そんな方におすすめの特集はこちら。',
    imageData: fav009,
    spImageData: spFav009,
  },
  {
    title: '駅チカ [マンション]',
    href: '/buy/mansion/special/ekichika',
    description:
      '通勤・通学・お出かけにも便利な、駅から近い家に住みたい！そんな方におすすめの駅近物件特集はこちら。',
    imageData: fav010,
    spImageData: spFav010,
  },
];
export interface SpecialItem {
  type: RentSpecialType | BuySpecialType;
  title: string;
  href: string;
  pcTitleImage: StaticImageData;
  description: string;
  metaDescription: string;
  metaTitle: string;
}

export const rentSpecialTypeDataSet: SpecialItem[] = [
  {
    type: 'shinchiku',
    title: '新築賃貸物件',
    href: '/rent/home/special/shinchiku',
    pcTitleImage: titleIconRentHome,
    description:
      '新築の賃貸住宅を借りるなら、物件検索サイト「ラビーネット不動産」で。新築賃貸マンション・新築賃貸アパートの物件情報を住みたいエリア/沿線・駅から検索し、賃料/間取り/駅からの徒歩といった希望条件で絞り込むことができます。このほか、物件の探し方から契約、引越、入居時の手続まで、お部屋探しに役立つ情報が満載。このサイトは全国組織の業界団体である公益社団法人全日本不動産協会が運営する不動産情報サイトのため、安心して物件をお探しいただけます。住まい探しのことならラビーネット不動産にお任せください！',
    metaDescription:
      '新築賃貸物件住宅を探すなら【ラビーネット不動産】日本全国の賃貸マンション・アパート・一戸建て物件情報を検索することができます。こだわり条件検索にもしっかり対応。ラビーネット不動産なら、あなたにぴったりの新築賃貸物件住宅がきっと見つかります。',
    metaTitle:
      '新築賃貸物件｜賃貸マンション・アパート・一戸建ての物件検索なら【ラビーネット不動産】',
  },
  {
    type: 'petok',
    title: 'ペット相談可物件',
    href: '/rent/home/special/petok',
    pcTitleImage: titleIconRentHome,
    description:
      'ペット相談可能な賃貸住宅を借りるなら、物件検索サイト「ラビーネット不動産」で。ペット相談物件の物件情報を住みたいエリア/沿線・駅から検索し、賃料/間取り/駅からの徒歩といった希望条件で絞り込むことができます。このほか、物件の探し方から契約、引越、入居時の手続まで、お部屋探しに役立つ情報が満載。このサイトは全国組織の業界団体である公益社団法人全日本不動産協会が運営する不動産情報サイトのため、安心して物件をお探しいただけます。住まい探しのことならラビーネット不動産にお任せください！',
    metaDescription:
      'ペット相談可物件住宅を探すなら【ラビーネット不動産】日本全国の賃貸マンション・アパート・一戸建て物件情報を検索することができます。こだわり条件検索にもしっかり対応。ラビーネット不動産なら、あなたにぴったりのペット相談可物件住宅がきっと見つかります。',
    metaTitle:
      'ペット相談可物件｜賃貸マンション・アパート・一戸建ての物件検索なら【ラビーネット不動産】',
  },
  {
    type: 'single',
    title: '一人暮らし向け物件',
    href: '/rent/home/special/single',
    pcTitleImage: titleIconRentHome,
    description:
      '一人暮らし向けの賃貸住宅を借りるなら、物件検索サイト「ラビーネット不動産」で。ワンルーム・1K・1DK・1LDKといった一人暮らし向けの物件情報を住みたいエリア/沿線・駅から検索し、賃料/駅からの徒歩/築年数といった希望条件で絞り込むことができます。このほか、物件の探し方から契約、引越、入居時の手続まで、お部屋探しに役立つ情報が満載。このサイトは全国組織の業界団体である公益社団法人全日本不動産協会が運営する不動産情報サイトのため、安心して物件をお探しいただけます。住まい探しのことならラビーネット不動産にお任せください！',
    metaDescription:
      '一人暮らし向け物件住宅を探すなら【ラビーネット不動産】日本全国の賃貸マンション・アパート・一戸建て物件情報を検索することができます。こだわり条件検索にもしっかり対応。ラビーネット不動産なら、あなたにぴったりの一人暮らし向け物件住宅がきっと見つかります。',
    metaTitle:
      '一人暮らし向け物件｜賃貸マンション・アパート・一戸建ての物件検索なら【ラビーネット不動産】',
  },
  {
    type: 'kourei',
    title: '高齢者相談可物件',
    href: '/rent/home/special/kourei',
    pcTitleImage: titleIconRentHome,
    description:
      '高齢者相談可能な賃貸住宅を借りるなら、物件検索サイト「ラビーネット不動産」で。高齢者の入居相談が可能な賃貸物件の物件情報を住みたいエリア/沿線・駅から検索し、賃料/間取り/駅からの徒歩といった希望条件で絞り込むことができます。このほか、物件の探し方から契約、引越、入居時の手続まで、お部屋探しに役立つ情報が満載。このサイトは全国組織の業界団体である公益社団法人全日本不動産協会が運営する不動産情報サイトのため、安心して物件をお探しいただけます。住まい探しのことならラビーネット不動産にお任せください！',
    metaDescription:
      '高齢者相談可物件住宅を探すなら【ラビーネット不動産】日本全国の賃貸マンション・アパート・一戸建て物件情報を検索することができます。こだわり条件検索にもしっかり対応。ラビーネット不動産なら、あなたにぴったりの高齢者相談可物件住宅がきっと見つかります。',
    metaTitle:
      '高齢者相談可物件｜賃貸マンション・アパート・一戸建ての物件検索なら【ラビーネット不動産】',
  },
  {
    type: 'ekichika',
    title: '駅チカ物件',
    href: '/rent/home/special/ekichika',
    pcTitleImage: titleIconRentHome,
    description:
      '駅から近い賃貸住宅を借りるなら、物件検索サイト「ラビーネット不動産」で。通勤・通学・お出かけに便利な駅近賃貸物件の物件情報を住みたいエリア/沿線・駅から検索し、賃料/間取り/築年数といった希望条件で絞り込むことができます。このほか、物件の探し方から契約、引越、入居時の手続まで、お部屋探しに役立つ情報が満載。このサイトは全国組織の業界団体である公益社団法人全日本不動産協会が運営する不動産情報サイトのため、安心して物件をお探しいただけます。住まい探しのことならラビーネット不動産にお任せください！',
    metaDescription:
      '駅チカ物件住宅を探すなら【ラビーネット不動産】日本全国の賃貸マンション・アパート・一戸建て物件情報を検索することができます。こだわり条件検索にもしっかり対応。ラビーネット不動産なら、あなたにぴったりの駅チカ物件住宅がきっと見つかります。',
    metaTitle: '駅チカ物件｜賃貸マンション・アパート・一戸建ての物件検索なら【ラビーネット不動産】',
  },
];

export const buySpecialTypeDataSet: SpecialItem[] = [
  {
    type: 'shinchiku',
    title: '新築物件',
    href: '/buy/mansion/special/shinchiku',
    pcTitleImage: titleIconBuyMansion,
    description:
      '新築マンションの購入をご検討中なら、物件検索サイト「ラビーネット不動産」で。新築売マンションの物件情報を住みたいエリア/沿線・駅から検索し、価格/間取り/駅からの徒歩といった希望条件で絞り込むことができます。このほか、物件の探し方から契約、引越、入居時の手続まで、お部屋探しに役立つ情報が満載。このサイトは全国組織の業界団体である公益社団法人全日本不動産協会が運営する不動産情報サイトのため、安心して物件をお探しいただけます。新築マンションのことならラビーネット不動産にお任せください！',
    metaDescription:
      '新築物件マンションを探すなら【ラビーネット不動産】日本全国のマンション情報を検索することができます。こだわり条件検索にもしっかり対応。ラビーネット不動産なら、あなたにぴったりの新築物件マンションがきっと見つかります。',
    metaTitle: '新築物件｜中古マンション・新築マンションの物件検索なら【ラビーネット不動産】',
  },
  {
    type: 'petok',
    title: 'ペット相談可物件',
    href: '/buy/mansion/special/petok',
    pcTitleImage: titleIconBuyMansion,
    description:
      'ペット相談可能なマンションの購入をご検討中なら、物件検索サイト「ラビーネット不動産」で。ペット相談可能な売マンションの物件情報を住みたいエリア/沿線・駅から検索し、価格/間取り/駅からの徒歩といった希望条件で絞り込むことができます。このほか、物件の探し方から契約、引越、入居時の手続まで、お部屋探しに役立つ情報が満載。このサイトは全国組織の業界団体である公益社団法人全日本不動産協会が運営する不動産情報サイトのため、安心して物件をお探しいただけます。新築マンション・中古マンションのことならラビーネット不動産にお任せください！',
    metaDescription:
      'ペット相談可物件マンションを探すなら【ラビーネット不動産】日本全国のマンション情報を検索することができます。こだわり条件検索にもしっかり対応。ラビーネット不動産なら、あなたにぴったりのペット相談可物件マンションがきっと見つかります。',
    metaTitle:
      'ペット相談可物件｜中古マンション・新築マンションの物件検索なら【ラビーネット不動産】',
  },
  {
    type: 'akiya',
    title: '空き家バンク登録物件',
    href: '/buy/house/special/akiya',
    pcTitleImage: titleIconBuyHouse,
    description:
      '空き家住宅の購入をご検討中なら、物件検索サイト「ラビーネット不動産」で。空き家バンク登録物件となっている一戸建ての物件情報を住みたいエリア/沿線・駅から検索し、価格/間取り/駅からの徒歩といった希望条件で絞り込むことができます。このほか、物件の探し方から契約、引越、入居時の手続まで、お部屋探しに役立つ情報が満載。このサイトは全国組織の業界団体である公益社団法人全日本不動産協会が運営する不動産情報サイトのため、安心して物件をお探しいただけます。中古一戸建て・一軒家のことならラビーネット不動産にお任せください！',
    metaDescription:
      '空き家バンク登録物件を探すなら【ラビーネット不動産】日本全国の一戸建て情報を検索することができます。こだわり条件検索にもしっかり対応。ラビーネット不動産なら、あなたにぴったりの空き家バンク登録物件がきっと見つかります。',
    metaTitle:
      '空き家バンク登録物件｜中古一戸建て・新築一戸建ての物件検索なら【ラビーネット不動産】',
  },
  {
    type: 'car2',
    title: '駐車場2台分物件',
    href: '/buy/house/special/car2',
    pcTitleImage: titleIconBuyHouse,
    description:
      '駐車場2台分の戸建の購入をご検討中なら、物件検索サイト「ラビーネット不動産」で。駐車場2台分の売一戸建ての物件情報を住みたいエリア/沿線・駅から検索し、価格/間取り/面積といった希望条件で絞り込むことができます。このほか、物件の探し方から契約、引越、入居時の手続まで、お部屋探しに役立つ情報が満載。このサイトは全国組織の業界団体である公益社団法人全日本不動産協会が運営する不動産情報サイトのため、安心して物件をお探しいただけます。新築一戸建て・中古一戸建て・一軒家のことならラビーネット不動産にお任せください！',
    metaDescription:
      '駐車場2台分物件を探すなら【ラビーネット不動産】日本全国の一戸建て情報を検索することができます。こだわり条件検索にもしっかり対応。ラビーネット不動産なら、あなたにぴったりの駐車場2台分物件がきっと見つかります。',
    metaTitle: '駐車場2台分物件｜中古一戸建て・新築一戸建ての物件検索なら【ラビーネット不動産】',
  },
  {
    type: 'ekichika',
    title: '駅チカ物件',
    href: '/buy/mansion/special/ekichika',
    pcTitleImage: titleIconBuyMansion,
    description:
      '駅から近いマンションの購入をご検討中なら、物件検索サイト「ラビーネット不動産」で。通勤・通学・お出かけに便利な駅近の売マンションの物件情報を住みたいエリア/沿線・駅から検索し、価格/間取り/築年数といった希望条件で絞り込むことができます。このほか、物件の探し方から契約、引越、入居時の手続まで、お部屋探しに役立つ情報が満載。このサイトは全国組織の業界団体である公益社団法人全日本不動産協会が運営する不動産情報サイトのため、安心して物件をお探しいただけます。新築マンション・中古マンションのことならラビーネット不動産にお任せください！',
    metaDescription:
      ' 駅チカ物件マンションを探すなら【ラビーネット不動産】日本全国のマンション情報を検索することができます。こだわり条件検索にもしっかり対応。ラビーネット不動産なら、あなたにぴったりの駅チカ物件マンションがきっと見つかります。',
    metaTitle: '駅チカ物件｜中古マンション・新築マンションの物件検索なら【ラビーネット不動産】',
  },
];

export interface DetailPageMetaDeta extends BaseCategoryItem {
  metaDescription: string;
}

export const rentPropertyMetaDetaSet: DetailPageMetaDeta[] = [
  {
    type: 'rent',
    category: 'home',
    title: '賃貸マンション・アパート・一戸建て',
    href: '/rent/home',
    metaDescription: 'ラビーネット不動産なら、あなたにぴったりの賃貸住宅がきっと見つかります。',
  },
  {
    type: 'rent',
    category: 'parking',
    title: '貸駐車場',
    href: '/rent/parking',
    metaDescription: 'ラビーネット不動産なら、あなたにぴったりの賃貸駐車場がきっと見つかります。',
  },
  {
    type: 'rent',
    category: 'shop',
    title: '貸店舗',
    href: '/rent/shop',
    metaDescription:
      'ラビーネット不動産なら、あなたにぴったりの賃貸店舗・貸店舗がきっと見つかります。',
  },
  {
    type: 'rent',
    category: 'office',
    title: '貸事務所',
    href: '/rent/office',
    metaDescription: 'ラビーネット不動産なら、あなたにぴったりの貸事務所がきっと見つかります。',
  },
  {
    type: 'rent',
    category: 'land',
    title: '貸土地',
    href: '/rent/land',
    metaDescription: 'ラビーネット不動産なら、あなたにぴったりの貸し土地がきっと見つかります。',
  },
  {
    type: 'rent',
    category: 'other',
    title: '貸ビル・倉庫・その他',
    href: '/rent/other',
    metaDescription: 'ラビーネット不動産なら、あなたにぴったりの物件がきっと見つかります。',
  },
];

export const buyPropertyMetaDataSet: DetailPageMetaDeta[] = [
  {
    type: 'buy',
    category: 'mansion',
    title: '売マンション',
    href: '/buy/mansion',
    metaDescription:
      'ラビーネット不動産なら、あなたにぴったりの中古マンション・新築マンションがきっと見つかります。',
  },
  {
    type: 'buy',
    category: 'house',
    title: '売一戸建て',
    href: '/buy/house',
    metaDescription:
      'ラビーネット不動産なら、あなたにぴったりの中古一戸建て・新築一戸建てがきっと見つかります。',
  },
  {
    type: 'buy',
    category: 'shop',
    title: '売店舗',
    href: '/buy/shop',
    metaDescription: 'ラビーネット不動産なら、あなたにぴったりの売り店舗がきっと見つかります。',
  },
  {
    type: 'buy',
    category: 'office',
    title: '売事務所',
    href: '/buy/office',
    metaDescription: 'ラビーネット不動産なら、あなたにぴったりの売り事務所がきっと見つかります。',
  },
  {
    type: 'buy',
    category: 'land',
    title: '売土地',
    href: '/buy/land',
    metaDescription: 'ラビーネット不動産なら、あなたにぴったりの土地・売り地がきっと見つかります。',
  },
  {
    type: 'buy',
    category: 'other',
    title: '売ビル・一括マンション・その他',
    href: '/buy/other',
    metaDescription: 'ラビーネット不動産なら、あなたにぴったりの物件がきっと見つかります。',
  },
];

export const resortPropertyMetaDataSet: DetailPageMetaDeta[] = [
  {
    type: 'resort',
    category: 'mansion',
    title: 'リゾートマンション',
    href: '/buy/resort/mansion',
    metaDescription:
      'ラビーネット不動産なら、あなたにぴったりのリゾートマンションがきっと見つかります。',
  },
  {
    type: 'resort',
    category: 'villa',
    title: '別荘・保養所',
    href: '/buy/resort/villa',
    metaDescription: 'ラビーネット不動産なら、あなたにぴったりの別荘・保養所がきっと見つかります。',
  },
  {
    type: 'resort',
    category: 'land',
    title: '別荘地・土地',
    href: '/buy/resort/land',
    metaDescription: 'ラビーネット不動産なら、あなたにぴったりの別荘地・土地がきっと見つかります。',
  },
];

export const agencyMetaDataSet: DetailPageMetaDeta = {
  title: '不動産会社',
  href: '/agency',
  category: 'agency',
  metaDescription: 'ラビーネット不動産なら、あなたにぴったりの不動産会社がきっと見つかります。',
};

export interface PropertyMetricsPathData {
  type: string;
  category: string;
  path: string;
  key: string;
  count?: number;
}

export const propertyMetricsDataSet: PropertyMetricsPathData[] = [
  {
    type: 'rent',
    category: 'home',
    path: 'rent/home',
    key: 'rent_room_total_size',
  },
  {
    type: 'rent',
    category: 'parking',
    path: 'rent/parking',
    key: 'rent_parking_space_total_size',
  },
  {
    type: 'rent',
    category: 'shop',
    path: 'rent/shop',
    key: 'rent_store_total_size',
  },
  {
    type: 'rent',
    category: 'office',
    path: 'rent/office',
    key: 'rent_office_total_size',
  },
  {
    type: 'rent',
    category: 'land',
    path: 'rent/land',
    key: 'rent_land_total_size',
  },
  {
    type: 'rent',
    category: 'other',
    path: 'rent/other',
    key: 'rent_other_total_size',
  },
  {
    type: 'buy',
    category: 'mansion',
    path: 'buy/mansion',
    key: 'sale_mansion_total_size',
  },
  {
    type: 'buy',
    category: 'house',
    path: 'buy/house',
    key: 'sale_detached_house_total_size',
  },
  {
    type: 'buy',
    category: 'shop',
    path: 'buy/shop',
    key: 'sale_store_total_size',
  },
  {
    type: 'buy',
    category: 'office',
    path: 'buy/office',
    key: 'sale_office_total_size',
  },
  {
    type: 'buy',
    category: 'land',
    path: 'buy/land',
    key: 'sale_land_total_size',
  },
  {
    type: 'buy',
    category: 'other',
    path: 'buy/other',
    key: 'sale_other_total_size',
  },
  {
    type: 'resort',
    category: 'mansion',
    path: 'buy/resort/mansion',
    key: 'sale_resort_mansion_total_size',
  },
  {
    type: 'resort',
    category: 'villa',
    path: 'buy/resort/villa',
    key: 'sale_resort_villa_total_size',
  },
  {
    type: 'resort',
    category: 'land',
    path: 'buy/resort/land',
    key: 'sale_resort_land_total_size',
  },
];
